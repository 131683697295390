/* MyTrophyCase.css */
.my-trophy-case-container {
    max-height: 380px;
    overflow: auto;
    margin-top: 15px;
    font-family: Avenir Next LT Pro;
}
/* Custom scrollbar styles */
.my-trophy-case-container::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
}

.my-trophy-case-container::-webkit-scrollbar-track {
    background: transparent; 
}

.my-trophy-case-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; /* Make the thumb rounded */
}

.my-trophy-case-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Firefox scrollbar styles */
.my-trophy-case-container {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}
.my-trophy-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to the left */
    gap: 20px;
    margin-bottom: 20px; /* Add space between card sections */
}

.my-trophy-card {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row on medium screens */
    max-width: calc(50% - 20px);
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    color: black; /* Ensure text color is visible */
    background-color: white; /* Ensure the card background is white */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.my-trophy-card-body {
    padding: 16px;
}

.my-trophy-card-title {
    font-size: 1.25em;
    margin-bottom: 8px;
    color: black; /* Ensure the card title is black */
}

.my-trophy-lvl1net {
    font-size: 1.5em; /* Make the LVL_1_NET value larger */
    font-weight: bold;
}



.record-trophy .my-trophy-lvl1net {
    color: rgb(5, 144, 28); /* Match the color of the section */
}

.bronze-trophy .my-trophy-lvl1net {
    color: #cd7f32; /* Bronze color */
}

.silver-trophy .my-trophy-lvl1net {
    color: #c0c0c0; /* Silver color */
}

.gold-trophy .my-trophy-lvl1net {
    color: #ffd700; /* Gold color */
}

.platinum-trophy .my-trophy-lvl1net {
    color: #e5e4e2; /* Platinum color */
}

.diamond-trophy .my-trophy-lvl1net {
    color: #b9f2ff; /* Diamond color */
}

.my-club-title-container {
    display: flex;
    align-items: center;
}

.my-club-title {
    margin-right: 10px;
}

.my-club-icons {
    display: flex;
}

.my-club-icon {
    margin-left: -5px; /* Adjust the overlap */
    font-size: 1.5em;
    color: inherit; /* Match the text color */
}

.my-club-icon img {
    width: 24px;
    height: 24px;
}

.my-trophy-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }
  
  .info-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #0055bc;
  }
  

  .modal-content p {
    margin-bottom: 10px;
}

.modal-content ul {
    padding-left: 20px;
}

.modal-content ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.modal-content ul li img {
    margin-right: 10px;
    width: 20px; /* Adjust the size as needed */
    height: auto;
}

  
@media (max-width: 1340px) {
    .my-trophy-card {
        flex: 1 1 100%; /* 1 card per row on small screens */
        max-width: 100%;
    }
}

/* Overlay container */
.get-user-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Modal container */
  .get-user-info-modal {
    background: #ffffff;
    padding: 24px 32px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  /* Header styles */
  .get-user-info-modal h2 {
    margin: 0 0 16px;
    font-size: 24px;
    color: #333;
  }
  
  /* Input label */
  .get-user-info-label {
    font-size: 14px;
    color: #555;
    display: block;
    text-align: left;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  /* Input container */
  .get-user-info-input-group {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  /* Input styles */
  .get-user-info-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    transition: border 0.3s;
  }
  
  .get-user-info-input:focus {
    border: 1px solid #007BFF;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Error message */
  .get-user-info-error {
    color: #d9534f;
    font-size: 12px;
    margin-top: 4px;
  }
  
  /* Submit button */
  .get-user-info-submit-button {
    padding: 10px 16px;
    font-size: 16px;
    background: #00558c;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 20px;
  }
  
  .get-user-info-submit-button:hover {
    background: #0056b3;
  }
/* Input container */
.get-user-info-input-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between input and icon */
    position: relative;
  }
  
  .get-user-info-input {
    flex: 1;
  }
  
  /* Checkmark icon container */
  .get-user-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; /* Set consistent size */
    height: 24px; /* Set consistent size */
  }
  
.recruiting-table-container {
  width: 100%;
  overflow-x: auto; /* Allows the table to fit width without horizontal scrolling */
  overflow-y: visible; /* Ensures no vertical constraints */
  margin-top: 20px;
  box-sizing: border-box; /* Ensures padding is included in width/height calculations */
  position: relative;
}
.recruiting-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.452);
}

.recruiting-table thead {
  background-color: #00558c;
  color: #fff;
}

.recruiting-table th,
.recruiting-table td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping and causing columns to shrink */
}

.recruiting-table th {
  letter-spacing: 0.05em;
}

.recruiting-table th.sortable:hover {
  cursor: pointer;
}

.recruiting-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.recruiting-table tbody tr {
  transition: background-color 0.3s;
}

.recruiting-table tbody tr:hover {
  background-color: #ebebeb;
}

.recruiting-table td {
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.recruiting-table tbody tr:last-child td {
  border-bottom: none;
}

.recruiting-table-name {
  font-weight: bold;
  color: #333;
}

.recruiting-table-step {
  font-style: italic;
  color: #555;
}

.recruiting-table-email {
  color: #00796b;
  text-decoration: underline;
  cursor: pointer;
}

.recruiting-table-phone {
  color: #555;
}

.recruiting-table-email:hover {
  color: #004d40;
}

.recruiting-table-phone:hover {
  color: #333;
}

/* Applicants affiliate link styling */
.applicants-affiliate-link {
  margin-bottom: 20px;
  text-align: center;
}

.applicants-affiliate-link a {
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
  color: blue;
}

.applicants-affiliate-link a:hover {
  color: darkblue;
}

.applicants-affiliate-link p {
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.applicants-affiliate-link p:hover {
  color: rgb(58, 58, 58);
}
/* Add this to your CSS file */
.popup-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  border-radius: 4px;
  width: 200px; /* Adjust width if necessary */
}

.popup-menu button {
  display: block;
  width: 100%;
  padding: 8px;
  background: #f9f9f9;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s ease;
}

.popup-menu button:hover {
  background: #e6e6e6;
}

.recruiting-table tbody tr.popup-open {
  background-color: #e0e0e0; /* Slightly darker color to indicate the selected row */
}

button img {
  vertical-align: middle;
  height: 15px;
  width: 15px;
}

td button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Hide the email column on mobile screens */
@media (max-width: 480px) {
  .recruiting-table th:nth-child(4),
  .recruiting-table td:nth-child(4) {
      display: none;
  }
}


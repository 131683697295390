.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 0;
    border-radius: 8px;
    z-index: 1001;
    width: 100%;
    max-height: 800px; /* Adjust as needed */
    overflow-y: auto;

}
/* Media query for mobile devices */
@media (max-width: 600px) { /* Adjust the max-width as needed */
    .modal-content {
        max-height: 90vh; /* Adjust the height to be a percentage of the viewport height */
    }
}
.inactive-row {
    background-color: #f0f0f0; /* Slightly greyed out background */
    color: #a0a0a0; /* Slightly greyed out text */
}
.agent-name-link {
    color: rgb(0, 0, 27);
    cursor: pointer;
    text-decoration: bold;
}

.agent-name-link:hover {
    color: #00558c;
}

body {
    font-family: Arial, sans-serif;
  }
  
  .calendar {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    margin: 20px;
  }
  
  .calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  
  .calendar-header-day {
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 2px solid #ccc;
    background-color: #f1f1f1;
  }
  
  .calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .calendar-day {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
  }

  .calendar-day:hover {
    background-color: #f1f1f1;
  }
  
  .calendar-day-header {
    background-color: #00558c;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .calendar-day-date {
    color: white;
    font-size: 1.2em;
    position: absolute;
    top: 4px;
    right: 10px;
  }
  
  .full-details {
    display: none;
    margin-top: 35px;
  }
  
  .mobile-alp {
    display: block;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-top: 20px;
  }
  
  .custom-modal .modal-content {
    max-height: none;
    overflow: visible;
  }
  
  .custom-modal .modal-body {
    padding: 20px;
  }
  
  .custom-modal .modal-header {
    padding: 15px;
    border-bottom: none;
  }
  
  .custom-modal .modal-footer {
    display: none; /* Hide footer if you don't need it */
  }
  
  .calendar-day p {
    font-size: 15px;
    margin-top: 2.5px; /* Adjust to avoid overlap with the header */
  }
  
  .highlight-week {
    background-color: #f1f1f1;
  }
  
  .highlight-week-header {
    background-color: green !important;
  }
  
  .highlight-today {
    color: rgb(255, 255, 255);
    background-color: green;
    border-radius: 50%;
    font-weight: bold;
    border: 2px solid rgb(255, 255, 255);
    display: inline-block;
    padding: 0em 0.50em;
  }
  
  .alp-only {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1em;
    margin-top: 30px;
    color: #00558c;
    font-weight: bold;
  }
  
  /* Updated CSS to support edit mode */

.calendar-day.greyed-out {
    background-color: #d3d3d3;
    cursor: default;
  }
  
  .calendar-day.greyed-out:hover {
    background-color: #d3d3d3; /* No hover effect for greyed-out days */
  }
  
  .success-indicator {
    display: inline-block;
    margin-left: 5px;
    color: green;
    font-weight: bold;
  }
  
  .alp-goal-container {
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    position: relative; /* Add this line */
  }
  
  .goals-edit-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #0056b3;
  }
  
  .goals-edit-button::before {
    content: "\270E"; /* Unicode for pencil icon */
    font-size: 1.5em;
  }
  
  .stats-container {
    margin-top: 20px;
    width: 80%;
    margin: 0 auto;
  }
  
  .stats-card {
    flex: 1;
    margin: 10px;
    min-width: 200px; /* Adjust as needed */
  }
  
  .custom-options button {
    margin: 5px;
    padding: 10px;
    border: none;
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .custom-options button:hover {
    background-color: #003d7a;
  }
  .alp-goal-text {
    font-size: 1.5em;
    margin-top: 10px;
    font-weight: bold;
    color: #0056b3;
  }
  
  .alp-goal-input {
    font-size: 1em;
    font-weight: bold;
    color: #0056b3;
    width: 100px;
    text-align: center;
    border: none;
    border-bottom: 2px solid #0056b3;
    background: none;
    -moz-appearance: textfield; /* Remove spinner buttons on Firefox */
    -webkit-appearance: none; /* Remove spinner buttons on Chrome, Safari, and Opera */
    appearance: none; /* Remove spinner buttons on other browsers */
  }
  
  .progress-bar {
    height: 30px;
    background-color: #28a745;
    border-radius: 10px;
    transition: width 0.4s ease;
  }
  
  .progress {
    background-color: #e9ecef;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .custom-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 320px;
    position: relative; /* Add this line */
    padding-bottom: 40px; /* Add space for buttons */
  }

  .custom-card p {
    margin: 8px;
  }
  
  .custom-card:hover {
    box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.1);
  }
  .custom-card-header {
    width: 100%;
    text-align: center;
    background-color: transparent;
    color: white;
    border-bottom: none;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
  }
  
  .custom-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    width: 100%; /* Ensure full width */
    height: 100%; /* Ensure full height */
  }
  
  .custom-options {
    display: flex;
    justify-content: center;
    gap: 10px; /* Add some space between the buttons */
    position: absolute; /* Position absolutely */
    bottom: 10px; /* Position at the bottom */
    width: 100%; /* Full width */
    padding: 0 20px; /* Add padding to align with card padding */
  }
  
  .custom-options button {
    flex: 1; /* Make buttons take equal space */
    padding: 5px 10px; /* Smaller padding for smaller buttons */
    font-size: 0.9rem; /* Smaller font size */
    background-color: #0056b3; /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-options button:hover {
    background-color: #003d7a;
  }
  
  .custom-options button.selected {
    background-color: #003d7a;
  }
  
  .custom-card-body > div:last-child {
    margin-top: 1rem; /* Add some space above the last child (e.g., selected text) */
  }
  .custom-card-body button {
    background-color: #00558c;
    color: white;
    border: none;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    font-size: 12px;
  }
  
  .custom-card-body button:hover {
    background-color: #0056b3;
  }
  
  .custom-options button {
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
  .custom-options button.selected {
    background-color: #00558c;
  }
  
  .custom-options button:hover {
    background-color: #5a6268;
  }
  /* Style for selected card */
/* Style for selected card */
.selected-card {
    background-color: #e9f7ff;
  }
/* Style for selected cards with different background colors */
.selected-card.custom {
    background-color: lavender;
}

.selected-card.pessimistic {
    background-color: #D0B39A;
    background-image: url(../../img/globe_bg_watermark.png);
    background-size: auto 105%;
    background-position-x: 95%;
    background-repeat: no-repeat; /* Prevent background repetition */
}

.selected-card.standard {
    background-color: rgba(216, 216, 216, 0.655);
    background-image: url(../../img/globe_bg_watermark.png);
    background-size: auto 105%;
    background-position-x: 95%;
    background-repeat: no-repeat; /* Prevent background repetition */
}

.selected-card.optimistic {
    background-color: #FCEEB9;
    background-image: url(../../img/globe_bg_watermark.png);
    background-size: auto 105%;
    background-position-x: 95%;
    background-repeat: no-repeat; /* Prevent background repetition */
}

/* Style for dimmer cards */
.dimmer {
    background-image: url(../../img/globe_bg_watermark.png);
    background-size: auto 105%;
    background-position-x: 95%;
    background-repeat: no-repeat; /* Prevent background repetition */
    opacity: 0.6;
  }
  
  /* New styles for dimmer expanded and dimmer collapsed */
  .dimmer-expanded {
    background-image: url(../../img/globe_bg_watermark.png);
    background-size: auto 105%;
    background-position-x: 95%;
    background-repeat: no-repeat;
    opacity: 0.6;
  }
  
  .dimmer-collapsed {
    background-image: none;
    opacity: 1;
  }
  /* Responsive styles */
  @media (min-width: 769px) {
    .full-details {
      display: block;
    }
  
    .mobile-alp {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .calendar-header, .calendar-body {
      grid-template-columns: repeat(7, minmax(0, 1fr));
      gap: 0px;
    }
  
    .calendar-header-day {
      font-size: 0.8em;
      padding: 5px 0;
    }
  
    .calendar-day {
      padding: 1px;
    }
  
    .calendar-day-header {
      padding: 12px;
    }
  
    .calendar-day-date {
      font-size: .75em;
    }
  
    .calendar-day p {
      font-size: 0.7em;
      margin-top: 30px; /* Adjust to avoid overlap with the header */
    }
  
    .alp-only {
      margin-top: 5px;
    }
  
    .full-details {
      display: block; /* Ensure full details are shown on mobile for future dates */
    }
  
    .mobile-alp {
      display: none; /* Hide ALP-only section on mobile for future dates */
    }
  }
  
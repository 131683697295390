.filters-container {
    position: relative;
}

.filter-button {
    padding: 5px 10px;
    background-color: #00558c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

}

.filter-button:hover {
    background-color: #0055bc;
    transition: 0.3s;
}

.filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.filter-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.filter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.select-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.select-container label {
    font-weight: bold;
}

.select-container select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.checklist-container {
  padding: 20px;
}

.progress-bar-container {
  position: sticky;
  top: 0;
  background-color: #ffffffaf;
  z-index: 1000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.checklist-progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 10px 0;
}

.checklist-progress-bar-fill {
  height: 100%;
  background-color: #00558c;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.checklist-section-grid {
  display: grid;
  gap: 1.5rem;
}

.checklist-large-tile {
  grid-column: 1 / -1;
}

.checklist-preparation-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  position: relative;
}

.checklist-preparation-columns::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: #ccc;
  transform: translateX(-50%);
}

.checklist-questions-column:first-child {
  padding-right: 20px;
}

.checklist-questions-column:last-child {
  padding-left: 20px;
}

.checklist-small-tile {
  grid-column: span 1;
}

@media (min-width: 768px) {
  .checklist-section-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.checklist-section-banner {
  background-color: #00558c;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px; /* Adds spacing between banner and section */
}

.checklist-section {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px; /* Adds spacing between sections */
}

.checklist-section-header {
  text-align: left;
  font-size: 1.8rem;
  color: #00558c;
  font-weight: bold;
  padding: 10px;
  margin-top: 20px;
}



.checklist-question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-checklist-question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.checklist-question-item label {
  font-size: 1rem;
  color: #333;
  flex-grow: 1;
}

.prep-checklist-question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.checklist-question-item input[type="checkbox"] {
  margin-left: auto;
  cursor: pointer;
}

.checklist-question-item input[type="text"],
.checklist-question-item select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 5px;
}

.locked-link,
.unlocked-link {
  display: flex;
  align-items: center;
  color: gray;
  text-decoration: none;
  pointer-events: none;
  margin-top: 15px;
  border-radius: 5px;
  padding: 5px;
}

.unlocked-link {
  color: #00558c;
  filter: none;
  pointer-events: auto;
}

.link-icon {
  margin-right: 8px;
}

.timeline-radio-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.timeline-radio-label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.timeline-radio-button {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  cursor: pointer;
}

.timeline-radio-label input[type="radio"] {
  display: none;
}

.timeline-radio-label input[type="radio"]:checked + .timeline-radio-button {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.dual-input-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.dual-input-header p {
  width: 100%;
  text-align: center;
}

.dual-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dual-input-container input[type="text"] {
  width: 9ch;
}

.long-form-answer label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.long-form-answer textarea {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  line-height: 1.4;
}

.release-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
}

.countdown-timer {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f3f3f3;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
}

.release-agent-selection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 1rem;
  color: #333;
  max-width: 250px;
}

.release-agent-selection label {
  font-weight: bold;
  color: #00558c;
}

.release-agent-selection select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #00558c;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.release-agent-selection select:focus {
  outline: none;
  border-color: #007bff;
}

.release-agent-selection select option {
  padding: 10px;
  color: #333;
  background-color: #ffffff;
}

.release-agent-selection select:hover {
  border-color: #007bff;
}

.release-top-bar .release-agent-selection {
  flex: 1;
}

/* Styling for increment buttons */
.increment-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #00558c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.increment-button:hover {
  background-color: #007bff;
}

.increment-button:active {
  background-color: #004680;
}

.progress-visualization {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px; /* Adds more space below each slider/button group */
}


/* Slider input styling */
.range-slider {
  -webkit-appearance: none; /* Remove default styling */
  width: 120px;
  height: 6px;
  background: #e0e0e0;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

.range-slider:hover {
  background-color: #d0d0d0;
}

/* Slider thumb styling */
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: #00558c;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.range-slider::-webkit-slider-thumb:hover {
  transform: scale(1.09);
  background-color: #007bff;
}

.range-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background-color: #00558c;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.range-slider::-moz-range-thumb:hover {
  transform: scale(1.1);
  background-color: #007bff;
}

.slider-labels {
  position: absolute;
  top: 25px; /* Adjust as needed to place below the slider */
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: #666;
  padding-left: 40px;
  padding-right: 40px;
}


.progress-count {
  position: absolute;
  top: 35px; /* Aligns with `slider-labels` */
  right: 0;
  font-size: 12px;
  color: #666;
}

/* Completed indicator styling */
.completed-indicator {
  color: green;
  font-weight: bold;
  margin-left: 8px;
}

.full-row {
  grid-column: 1 / -1;
  width: 100%;
  margin-top: 40px;
}
.open-spots-remaining {
  font-size: 14px;
  color: #333;
  text-align: center;
  padding: 5px 10px;
  font-weight: bold;
  background-color: #f3f3f3;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.bulk-action-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem; /* Space between buttons and table */
  height: 40px; /* Fixed height to prevent jumping */
  align-items: center;
}
.bulk-action-buttons {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

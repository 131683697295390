.transition-section h4 {
    font-family: 'Avenir Next LT Pro', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #00558c;
  }
  
  .questions_section {
    margin-top: 20px;
  }
  
  .question-group {
    margin-bottom: 20px;
  }
  
  .question-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question-label {
    flex: 1;
    text-align: left;
    font-size: 14px;
  }
  
  .radio-group {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    min-width: 200px;
  }
  
  .radio-group label {
    font-size: 14px;
    font-family: 'Calibri', sans-serif;
  }
  
  .checkbox-list {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .checkbox-group {
    margin-left: 10px;
  }
  
  .no-to-all-container {
    text-align: right;
  }
  
  .medbutton {
    padding: 4px 8px;
    font-size: 12px;
    background-color: #00558c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
/* Container Styles */
.more-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #cccccc7c;
    border-radius: 10px;
    font-family: Avenir Next LT Pro;
}
.more-form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background: transparent;
    border-radius: 10px;
    font-family: Avenir Next LT Pro;
  }
  
  /* Title Styles */
  .more-form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .more-date-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .more-header-with-toggle-and-filters {
    margin-top: 10px;
    display: flex;
    justify-content: space-between; /* Adjusts spacing between elements */
    align-items: center; /* Aligns items vertically */
    flex-wrap: nowrap; /* Prevents wrapping to the next line */
    gap: 10px; /* Adds spacing between buttons */
    font-family: Avenir Next LT Pro;
    color: rgb(66, 66, 66);
    padding-left: 10px;
}


  .date-range-container {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px; /* Add spacing between label and dropdown */
  }
  
  .recruiting-week-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .week-selector {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .week-selector:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  
  .week-indicator {
    position: absolute;
    top: -35px; /* Adjust distance above the date range */
    left: 50%;
    transform: translateX(-50%);
    background-color: #f0f8ff; /* Light background */
    color: #00548c; /* Darker text */
    border: #00548c solid 2px; /* Darker border */
    font-size: 12px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 4px;
    z-index: 1;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .week-indicator-wrapper {
    display: flex;
    align-items: center;
    gap: 6px; /* Adds small space between the text and spinner */
}

.more-spinner {
    width: 14px;
    height: 14px;
    transform: translateX(-50%);
    position: absolute;
    top: -30px; /* Adjust distance above the date range */
    left: 65%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top: 2px solid #00558c;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    visibility: hidden; /* Keeps space allocated, preventing shift */
}

.more-spinner.visible {
    visibility: visible;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  .more-date-range {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
    
  .more-week-button {
    background-color: transparent;
    color: grey;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 30px;
    transition: color 0.3s;
  }
  
  .more-week-button:hover {
    background-color: transparent;
    color: #d5d5d5;
  }
  

  /* Table Styles */
  .more-form-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background: white;
  }
  
  .more-form-table th,
  .more-form-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .more-form-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .more-form-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .more-form-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Input Styles */
  .more-form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .more-form-input:focus {
    border-color: #007bff;
  }
  
  .warning-message-container {
    display: flex;
    align-items: center;
    background-color: #fff9e6; /* Light yellow background */
    border: 2px solid #ffe4a1; /* Yellow border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px;
    margin-top: -5px;
    margin-bottom: 30px;
    gap: 15px; /* Space between the icon and text */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: ease 0.3s;
}

.warning-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffe4a1; /* Yellow background */
}

.warning-icon-svg {
    width: 24px;
    height: 24px;
    color: #ffb300; /* Dark yellow color for the icon */
}

.warning-message-content h5 {
    margin: 0;
    font-size: 1rem;
    color: #b58900; /* Dark yellow text for the header */
    font-weight: bold;
}

.warning-message-content p {
    margin: 0;
    color: #6c5d00; /* Subtle dark yellow for the paragraph */
    font-size: .8rem;
}


.success-message-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
    border: 2px solid #28a745;
    border-radius: 8px;
    padding: 10px 20px;
    background-color: #d4edda;
    color: #155724;
    margin-top: -5px;
    margin-bottom: 30px;
    gap: 15px; /* Space between the icon and text */
    transition: ease-in 0.3s;
}

.success-icon {
    margin-right: 16px;
    flex-shrink: 0; /* Prevent icon from stretching */
}

.success-icon-svg {
    width: 40px;
    height: 40px;
}

.success-message-content {
    display: flex;
    flex-direction: column; /* Stack the title and paragraph vertically */
    justify-content: flex-start; /* Align content to the left */
}

.success-message-content h5 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #155724;
}

.success-message-content p {
    margin: 0;
    font-size: 0.8rem;
    color: #155724;
}

  .more-button-group {
    display: flex;
    gap: 10px; /* Add space between buttons */
    justify-content: flex-start; /* Align buttons to the left */
    margin-top: 10px;
  }
  
  
  .no-recruit-notice-button {
    background-color: #ffd97f; /* Dark yellow background */
    color: #6c5d00; /* White text */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 8px 12px; /* Padding around text */
    font-size: 12px; /* Smaller font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Show pointer on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }

  .more-toggle-collapse-button {
    background-color: #ffe4a1; /* Match light yellow background */
    color: #6c5d00; /* Subtle dark yellow for text */
    border: 1px solid #ffe4a1; /* Match border with the notice */
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .success-more-toggle-collapse-button {
    background-color: #4f934c; /* Match light yellow background */
    color: #ffffff; /* Subtle dark yellow for text */
    border: 1px solid #155724; /* Match border with the notice */
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .success-more-toggle-collapse-button:hover {
    background-color: #155724; 
    color: white; /* White text for hover */
  }

  .no-recruit-notice-button:hover {
    background-color: #ffb300; /* Slightly darker yellow for hover */
    color: white; /* White text for hover */
  }

  .more-toggle-collapse-button:hover {
    background-color: #ffb300; /* Slightly darker yellow for hover */
    color: white; /* White text for hover */
  }
.toggle-row {
    margin-top: 1rem; /* Adjust the spacing as needed */
    text-align: center; /* Center the toggle within the container */
  }
  
  .collapse-toggle {
    cursor: pointer;
    font-weight: bold;
    display: inline-block; /* Keep it inline */
  }
  
  .collapse-icon {
    margin-right: 0.5rem; /* Space between the icon and label */
  }
  
  
/* Responsive Table for Mobile */
.vertical-table {
    display: none;
}

/* Horizontal Table */
.horizontal-table {
    display: table;
}

@media (max-width: 768px) {
    .horizontal-table {
        display: none;
    }

    .vertical-table {
        display: table;
    }


}
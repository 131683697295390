/* General layout styles */
.login-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: row; /* Default layout for larger screens */
}

.login-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.vertical-divider {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 80%;
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.custom-button {
  font-size: 18pt;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
  margin: 10px 0;
  width: 28ch;
  border: none;
  cursor: pointer;
}

.green-button {
  background-color: #319b42;
  color: #fff;
}

.green-button:hover {
  background-color: #60CC72;
}

.green-button:active {
  background-color: #D4F1D9;
}
.orange-button {
  background-color: #ed722f;
  color: #fff;
}

.orange-button:hover {
  background-color: #F5B693;
}

.orange-button:active {
  background-color: #FCE9DF;
}
.gray-button {
  background-color: #474c55;
  color: #fff;
}

.gray-button:hover {
  background-color: #7E8693;
}

.gray-button:active {
  background-color: #EDEEF0;
}
.section-header {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}
/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column; /* Stack layout for mobile */
    height: auto;
    padding: 20px;
  }

  .vertical-divider {
    display: none; /* Remove divider */
  }

  .section-header {
    display: none; /* Remove headers */
  }

  .right-content {
    margin-top: 20px;
    width: 100%;
  }

  .green-button {
    display: none; /* Hide "Launch Presentation without login" button */
  }

  .custom-button {
    width: 100%; /* Make buttons span full width */
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../public/fonts/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-light .navbar-nav .nav-link.dropdown-toggle::after {
  border-top-color: white;
}
.globe-loading-overlay {
  position: fixed; /* Or absolute, depending on your needs */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 9999; /* Ensure it covers other content */
  display: flex;
  justify-content: center; /* Center the animation horizontally */
  align-items: center; /* Center the animation vertically */
}

.globe-loading-animation {
  width: 100px; /* Adjust size as needed */
  animation: spin 2s linear infinite; /* Simple spin animation */
}

.nav-container {
  padding: 0 20px; /* Add padding to ensure space on the sides */
}

.app-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 800px;
  padding: 0 20px; /* Add padding to ensure space on the sides */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
    background-image: url('./img/globe_bg_watermark_blue.png');
    background-size: 155%;
    background-position-x: top;
    width: 100%;
}

.large-app-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  width: 100%;
}

.page-header {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}

/* Medium screens - 3 cards per row */
@media (max-width: 1024px) {
  .app-container {
      max-width: 1024px;
  }
}

/* Small screens - 2 cards per row */
@media (max-width: 768px) {
  .app-container {
      max-width: 768px;
  }
}

/* Extra small screens - 1 card per row */
@media (max-width: 480px) {
  .app-container {
      max-width: 480px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

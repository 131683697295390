.release-bar {
    cursor: pointer;
    border-radius: 8px;
  }
  
  .release-bar-instruction {
    text-align: center;
    font-weight: bold;
    color: #ff0000;
    margin-bottom: 8px;
  }
  
  .release-bar-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .release-bar-counter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .release-bar-title {
    font-weight: bold;
  }
  
  .release-bar-progress-container {
    width: 100%;
    background-color: #ddd;
    border-radius: 4px;
    margin: 8px 0;
    height: 10px;
  }
  
  .release-bar-progress {
    background-color: #00558c;
    height: 100%;
    border-radius: 4px;
  }
  
  .release-bar-countdown {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
  
.grid-content {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;
    padding-top: 10px;
    grid-column-gap: 10px;
    justify-content: center;
    align-content: center;
  }
  .grid-cat {
    background-color: #c8c8c81b;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
    min-height: 270px;
    width: 350px;
  }
  .category {
    color: #00558c;
    font-size: 20px;
    padding: 3px;
    margin-bottom: 15px;
    font-weight: bold;
    user-select: none;
    text-align: center;
  }
/* CSS for the resource actions */
.category-link {
  position: relative;
  padding: 5px;
  margin-left: 15px;
}

.resource-actions {
  display: none;
  position: absolute;
  right: 10px;
  top: 0;
}

.category-link:hover .resource-actions {
  display: inline-block;
}

.edit-btn, .delete-btn {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.delete-btn {
  background-color: #dc3545;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn:hover {
  background-color: #c82333;
}

.search-bar {
  margin-bottom: 15px;
}

  .category-link a {
    color: black;
    text-decoration: none;
  }
  .category-link a:hover {
    color: #DAD0BC;
  }
  @media (max-width: 1500px) {
    .grid-content {
      grid-template-columns: repeat(3, auto);
    }
  }
  @media (max-width: 1200px) {
    .grid-content {
      grid-template-columns: repeat(2, auto);
    }
  }
  @media (max-width: 750px) {
    .grid-content {
      grid-template-columns: auto;
    }
  }
  
/* src/App.css */
.email-form {
    margin: 0 auto;
  }
  
  .email-form h2 {
    text-align: center;
  }
  
  .email-form label {
    display: block;
    margin-top: 10px;
  }
  
  .email-form input, .email-form select, .email-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }

  .email-form textarea {
    height: 200px;
  }
  
  .email-form button {
    display: block;
    width: 50%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .email-form button:hover {
    background-color: #0056b3;
  }
  /* EmailForm.css */
.ckeditor-container {
    position: relative;
    height: 400px;
    margin-bottom: 20px;
  }
  
  .ck-editor__editable_inline {
    height: 100%;
    resize: vertical;
    overflow: auto;
  }
  
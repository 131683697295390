.navbar-sticky-admin {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #525252;
    height: auto;
    display: flex;
    flex-direction: column;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand-custom {
    height: 100px;
}

.navbar-links {
    display: flex;
    align-items: center;
    gap: 10px;
}

.secondary-navbar {
    width: 100%;
    background-color: white !important;
}

.secondary-navbar .nav {
    display: flex;
    justify-content: center;
    width: 100%;
}

.secondary-navbar .nav-link {
    color: #000 !important;
    margin: 0 10px;
}

.secondary-navbar .nav-link.active {
    font-weight: bold;
    color: #007bff !important;
}

@media screen and (max-width: 768px) {
    .navbar-brand-custom {
        height: 80px;
    }
    .navbar-links {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .navbar-brand-custom {
        height: 60px;
    }
}

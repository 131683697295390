
  
  .profile-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1; /* Ensure it takes equal width alongside profile-right */
    max-width: 300px; /* Optional: Limit the maximum width */
  }
  
  .profile-right {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px; /* Space between inputs */
    flex: 2; /* Allow profile-right to take more space than profile-left */
    max-width: 400px; /* Optional: Limit the maximum width */
  }
  .profile-container {
    display: flex;
    justify-content: center; /* Center the profile content horizontally */
    align-items: flex-start; /* Align items at the top */
    gap: 40px; /* Space between profile-left and profile-right */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    font-family: Avenir Next LT Pro, sans-serif;
    margin-bottom: 20px; /* Add spacing below the profile section */
    margin-top: 20px;
  }
  
  .uplines-hierarchy-container {
    display: flex; /* Use flexbox for side-by-side layout */
    justify-content: space-between; /* Add spacing between the two sections */
    align-items: flex-start; /* Align items to the top */
    gap: 20px; /* Space between the uplines and hierarchy table */
    font-family: Avenir Next LT Pro, sans-serif;
  }
  
  .uplines-container {
    flex: 1; /* Allow the uplines section to take up some space */
    max-width: 300px; /* Optional: Limit maximum width */
    background-color: transparent; /* Optional: Add background for distinction */
    padding: 20px; /* Add padding inside the section */
    border-radius: 8px; /* Optional: Round corners */
  }
  
  .hierarchy-table-container {
    flex: 2; /* Allow the hierarchy table to take up more space */
    padding: 20px; /* Add padding inside the section */
    background-color: transparent; /* Optional: Add background color */
    border-radius: 8px; /* Optional: Round corners */
  }
  
  .contact-info-container {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
    gap: 10px; /* Add spacing between inputs */
  }
  
  .contact-info-group input {
    width: 100%; /* Ensure the input spans the full width of its parent */
    font-size: 12px;
  }
  
  .contact-info-group label {
    font-size: 12px;
    color: #333; /* Dark gray text color */
  }
  /* Existing styles remain unchanged */
  .image-container {
    position: relative;
    margin-bottom: 20px;
  }
  
  .image-container img {
    object-fit: cover;
    width: 275px;
    height: 275px;
    border-radius: 50%;
    border: 4px solid #ddd;
  }
  
  .edit-button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #00558c;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .dropdown-menu {
    display: none;
    position: absolute;
    bottom: -100px;
    left: 50%; /* Position relative to the parent */
    transform: translateX(-50%); /* Center horizontally */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
    font-size: 14px;
  }
  
  
  .image-container:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu a {
    display: block;
    padding: 10px 15px;
    color: #00558c;
    text-decoration: none;
  }
  
  .dropdown-menu a:hover {
    background-color: #f1f1f1;
    color: #0056b3;
  }
  
  .actions {
    margin-bottom: 20px;
  }
  
  .user-info h2 {
    margin-bottom: 10px;
  }
  
  .user-info p {
    margin: 5px 0;
  }
  
  .anniversary-message {
    color: green;
    font-weight: bold;
  }
  
  .formatted-agn-name {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
    color: #333;
  }
  
  .name-role-container {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Vertically center the items */
    gap: 10px; /* Add spacing between the name and the badge */
  }
  .user-role-badge {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column; /* Stack columns vertically on small screens */
      align-items: center;
    }
  
    .profile-left,
    .profile-right {
      max-width: 100%; /* Remove width limits */
      flex: unset; /* Remove flex allocation */
    }
  
    .profile-right {
      align-items: center; /* Center content for smaller screens */
    }
  }
  
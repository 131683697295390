.roleplay-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.roleplay-modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.roleplay-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.roleplay-modal-content {
  max-height: 70vh; /* Set max height for scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional padding to prevent scrollbar overlap */
}

.roleplay-modal-content h2 {
  margin-top: 0;
}

.roleplay-modal-content p {
  margin-top: 10px;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

:root {
    --radius: 8px;
    --border: 3px;  /* Adjust border thickness */
    --height: 15px; /* Adjust height for the tabs */
    --speed: 0.25s;
    --ease: ease;
}

.header-with-toggle {
    display: flex;
    justify-content: space-between; /* This will space out the children */
    align-items: center;
    width: 100%; /* Ensure the container takes the full width */
    margin-bottom: 0px; /* Add some space at the bottom */
}

.tabs {
    display: grid;
    grid-auto-flow: column;
    background: transparent;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    grid-auto-columns: minmax(80px, auto); /* Adjust width of each tab */
    position: relative;
    gap: 2px;
    border: var(--border) solid transparent;
    --count: 3; /* Three options: Weekly, MTD, YTD */
}


.tabs > input,
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0px;
}

.tabs:has(:checked:nth-of-type(1)) { --active: 0; }
.tabs:has(:checked:nth-of-type(2)) { --active: 1; }
.tabs:has(:checked:nth-of-type(3)) { --active: 2; }

.tabs label {
    padding: 6px 8px;
    cursor: pointer;
    text-align: center;
    display: grid;
    place-items: center;
    background: hsl(0, 0%, 84%);
    color: hsl(0, 0%, 0%);
    transition: background, color 0.25s;
    transition-timing-function: var(--ease, ease);
    border-radius: var(--radius); /* Apply the radius to all corners */
    border: 1px solid transparent;
    font-size: 12px;
    margin-bottom: 2px; /* Add some margin to the bottom */
}



input:not(:checked) + label:hover {
    background: hsl(0, 0%, 78%);
}

input:checked + label {
    background: #00558c;
    color: hsl(0, 0%, 100%);
}

input:checked + label::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
}


[data-glow] {
    position: relative;
}

[data-glow]:hover::before,
[data-glow]:hover::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: radial-gradient(
        calc(var(--size) * 0.5) at var(--x) var(--y),
        hsl(var(--base) 20% 20% / 0.2), transparent
    );
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    mix-blend-mode: overlay;
}

[data-glow]:hover {
    box-shadow: 0 0 15px #014da4; /* Updated box shadow color */
}

[data-glow]::before {
    mix-blend-mode: overlay;
}

[data-glow]::after {
    mix-blend-mode: color-dodge;
}

.counter {
    display: inline-flex;
    font-family: "Geist Sans", sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
}

.digit {
    padding: 0 0.1rem;
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}
.insight.positive {
    color: green;
}

.insight.negative {
    color: red;
}
.big-card {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.big-card .card-text {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}
.activity-metrics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}


  
  /* Container styles */
  .account-setup-register-container {
    width: 100%; /* Take full width of the app container */
    max-width: 800px; /* Wider container for alignment */
    margin: 50px auto;
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
    min-height: 78vh ;
    font-family: Avenir Next LT Pro, sans-serif;
  }
  /* Subtitle under the header */
.account-setup-description {
    font-size: 14px;
    margin-bottom: 0px;
    color: #555;
  }
  
    /* Subtitle under the header */
.account-setup-description-small {
    font-size: 10px;
    margin: auto;
    margin-bottom: 20px;
    color: #555;
    max-width: 400px;
  }
  
/* Back to Login link */
.account-setup-back-link {
    position: absolute;
    top: 0;
    left: 0; /* Align to the far left of the register container */
    text-decoration: none;
    font-size: 16px;
    color: #00558c;
    cursor: pointer;
}

.account-setup-back-link:hover {
    color: #00548cb7;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .account-setup-back-link {
        position: relative; /* Change to relative for proper positioning */
        top: auto; /* Reset top positioning */
        left: auto; /* Reset left positioning */
        margin-bottom: 10px; /* Add some spacing */
        display: block; /* Ensure it takes its own line */
        text-align: center; /* Center-align the link */
    }

    .account-setup-register-container {
        margin-top: 50px; /* Add some spacing to account for the moved back link */
    }
}

  .account-setup-back-link:hover {
    text-decoration: underline;
  }
  
  /* Header styles */
  .account-setup-register-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Form styles */
  .account-setup-register-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 400px; /* Keep the form narrower */
    margin: 0 auto; /* Center the form within the container */
  }
  
  .account-setup-form-field {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .account-setup-form-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .account-setup-form-input {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .account-setup-form-input[readonly] {
    background-color: #f0f0f0;
  }
  
  .account-setup-error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
  
  /* Submit button */
  .account-setup-submit-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .account-setup-submit-button:hover {
    background-color: #218838;
  }
  
/* Overlay background */
.info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Overlay content */
  .info-overlay-content {
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button */
  .close-overlay {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 24px;
    color: #555;
    cursor: pointer;
  }
  
  /* Info image */
  .info-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  

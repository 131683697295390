/* ./src/components/MORE/MoreCards.css */
.more-card {
  flex: 1; /* Allows cards to grow equally */
  flex-basis: 250px; /* Minimum base width */
  max-width: 100%; /* Prevent cards from exceeding container width */
  text-align: left;
  padding: 10px;
  position: relative;
  box-sizing: border-box; /* Ensure padding and borders don't affect size */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: ease 0.3s;
}

.more-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.more-cards-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
  font-family: Avenir Next LT Pro;
}
  
  .more-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .more-card-header h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    padding-top: 10px;
    padding-left: 5px;
  }
  
  .donut-container {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .donut-chart {
    width: 60px;
    height: 60px;
  }
  
  .donut-ring {
    stroke-width: 3;
  }
  
  .donut-segment {
    stroke-width: 3;
    transition: stroke-dasharray 0.5s;
  }
  
  .more-card-body {
    margin-top: 50px;
    text-align: center;

  }
  
  .more-value {
    font-size: 2.2rem;
    font-weight: bold;
    margin: 5px 0;
    color: #333;
  }
  
  .more-metric {
    font-size: 0.9rem;
    color: #555;
  }

  @media (max-width: 768px) {
    .more-card {
      width: 100%;
      text-align: center; /* Center content on smaller screens */
    }
  
    .more-card-header {
      flex-direction: column; /* Stack header elements vertically */
      align-items: center;
      gap: 10px;
    }
    .more-card-header h5 {
      font-size: .8rem; /* Adjust font size for smaller screens */
    }
    .more-value {
      font-size: .8rem; /* Adjust font size for smaller screens */
    }
    .more-metric {
      font-size: 0.6rem; /* Adjust font size for smaller screens */
    }

    .donut-container {
      position: static; /* Remove absolute positioning */
      margin: 10px 0 0; /* Add margin for spacing */
    }
  
    .donut-chart {
      width: 50px; /* Adjust size for smaller screens */
      height: 50px;
    }
  
    .card-body {
      margin-top: 20px; /* Adjust spacing */
    }
  }
  
.main-buttons-row {
    display: flex; /* Use flexbox to arrange items in a row */
    flex-direction: row; /* Set direction to row */
    justify-content: center; /* Center the buttons */
    gap: 15px; /* Space between buttons */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .main-custom-button {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1; /* Allow buttons to grow evenly */
    text-align: center;
    text-transform: uppercase;
    font-weight : bold;
  }
  
  .main-green-button {
    background-color: #28a745;
    color: white;
  }
  
  .main-green-button:hover {
    background-color: #218838;
  }
  
  .main-orange-button {
    background-color: #FFA069;
    color: white;
  }
  
  .main-orange-button:hover {
    background-color: #fba472;
  }
  
  .main-gray-button {
    background-color: #6c757d;
    color: white;
  }
  
  .main-gray-button:hover {
    background-color: #5a6268;
  }
  @media screen and (max-width: 768px) {
    .main-green-button {
        display: none; /* Hide "Launch Presentation without login" button */
      }
    }

    .lights-container {
      margin-bottom: 20px;
    }
    .light {
      animation: glow 1s infinite alternate;
    }
    
    @keyframes glow {
      0% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
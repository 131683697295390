.leaderboard-card {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  border-radius: 10px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 15px;
}
.leaderboard-card::-webkit-scrollbar {
  display: none;
}
.leaderboard-card th {
  color: black;
}
.total-hires-green {
  color: green;
}
.total-hires-yellow {
  color: rgb(191, 125, 1);
}
.total-hires-red {
  color: red;
}
.highlighted-row {
  background-color: #FFA069;
}
.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: blue;
}
.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  display: table;
}
.leaderboard-table thead {
  font-weight: bold;
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
}
.leaderboard-table thead, .leaderboard-table tbody tr {
  border-bottom: 1px solid #ddd;
}
.leaderboard-table tbody {
  display: block;
  max-height: 700px;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.leaderboard-table tbody::-webkit-scrollbar {
  display: none;
}
.leaderboard-table td, .leaderboard-table th {
  padding: 4px;
  text-align: left;
}

.leaderboard-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}

.leaderboard-table .medal-cell, .ranking-table .medal-cell {
  text-align: left;
}
.leaderboards-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 40px;
}
.date-picker-container {
  position: relative;
  margin-left: auto;
  padding-right: 0;
}
.leader-date-picker-overlay {
  left: auto;
  right: 359px;
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.calendar-button {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  margin-left: 5px;
}
.ranking-leaderboard-card {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
}
.ranking-leaderboard-card th {
  color: rgb(0, 0, 0);
  font-size: 12px;
  text-align: center;
}
.ranking-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  display: table;
}
.ranking-table thead {
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
}
.ranking-table tbody {
  display: block;
  width: 100%;
}
.ranking-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.ranking-table thead, .ranking-table tbody tr {
  border-bottom: 1px solid #ced2cd;
}
.ranking-table td, .ranking-table th {
  text-align: left;
}

.filters-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.mga-value, .mga-last-name {
  font-weight: normal;
  font-size: 10px;
  color: gray;
}
.date-filter-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.date-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ced2cd;
  font-size: 1em;
  color: inherit;
  cursor: pointer;
  text-align: center;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: -10px;
}
.date-select option {
  text-align: center;
}
.date-nav-button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.75em;
  cursor: pointer;
  transition: color 0.3s;
  margin-top: -10px;
}
.date-nav-button:hover {
  color: gray;
}


.data-toggle-buttons {
  display: flex;
  gap: 10px;
}
.data-toggle-buttons button {
  background-color: #f0f0f0;
  border: 1px solid #ced2cd;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.data-toggle-buttons button.active {
  background-color: #dcdcdc;
}
.data-toggle-buttons button:hover {
  background-color: #e0e0e0;
}
.table-overlay {
  position: relative;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  display: flex;
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.sticky-row-table {
  position: sticky;
  bottom: 0;
  background-color: #FFA069;
  z-index: 2;
  width: 100%;
  border-collapse: collapse;
  cursor: pointer;
}

.sticky-row-table td, .sticky-row-table th {
  padding: 4px;
  text-align: left;
}
.myview-leaderboard-card {
  max-height: 250px; /* Adjust the max-height as needed */
  overflow-y: auto;
}
.no-data-row {
  text-align: center;
  font-weight: bold;
  color: gray;
}
/* Add to your CSS file */
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
}

.info-icon {
  font-size: 0.9em;
  color: #00558c;
  vertical-align: middle;
  margin-left: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .leaderboards-header-container {
    flex-direction: column;
    align-items: stretch;
  }
  .calendar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .calendar-button, .presets-dropdown {
    flex: 1;
  }
  .calendar-button {
    font-size: 10px;
    margin-right: -20px;
  }
  .presets-dropdown {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .presets-overlay-mobile {
    top: calc(100% + 20px);
    left: 0;
    width: 100%;
  }
  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -5px;
  }
  .filters-row > div {
    flex: 1;
    font-size: 12px;
  }
  .ranking-leaderboard-card {
    margin-bottom: 10px;
    border: 1px solid #c8c8c8;
    font-size: 10px;
  }
  .ranking-leaderboard-card th {
    font-size: 9px;
  }

}
.rank-agent-name {
  font-size: 15px;
  font-weight: 500;
  width: 230px; /* Default width */
}
.rank-alp {
  font-size: 15px; /* Default font size to match .rank-agent-name */
}
.rank-column-header {
  font-size: 12px;
  width: 60px; /* Default width */
}

/* Adjustments for smaller screen sizes */
@media (max-width: 1024px) {
  .rank-agent-name {
    width: 200px;
    font-size: 14px;
  }
  .rank-alp {
    font-size: 14px;
  }
  .rank-column-header {
    width: 50px;
    font-size: 11px;
  }
}
@media (max-width: 900px) {
  .rank-agent-name {
    width: 180px;
    font-size: 13px;
  }
  .rank-alp {
    font-size: 13px;
  }
  .rank-column-header {
    width: 45px;
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .rank-agent-name {
    width: 160px;
    font-size: 12px;
  }
  .rank-alp {
    font-size: 12px;
  }
  .rank-column-header {
    width: 40px;
    font-size: 9px;
  }
  .myview-leaderboard-card
  {
    max-height: 230px;
  }
}
@media (max-width: 600px) {
  .rank-agent-name {
    width: 150px;
    font-size: 11px;
  }
  .rank-alp {
    font-size: 11px;
  }
  .rank-column-header {
    width: 35px;
    font-size: 9px;
  }

}
@media (max-width: 500px) {
  .rank-agent-name {
    width: 140px;
    font-size: 10px;
  }
  .rank-alp {
    font-size: 10px;
  }
  .rank-column-header {
    width: 30px;
    font-size: 8px;
  }
}
@media (max-width: 431px) {
  .rank-agent-name {
    width: 130px;
    font-size: 9px;
  }
  .rank-alp {
    font-size: 9px;
  }
  .rank-column-header {
    width: 25px;
    font-size: 8px;
  }
}
@media (max-width: 375px) {
  .rank-agent-name {
    width: 120px;
    font-size: 8px;
  }
  .rank-alp {
    font-size: 8px;
  }
  .rank-column-header {
    width: 25px;
    font-size: 7px;
  }
}
@media (max-width: 320px) {
  .rank-agent-name {
    width: 110px;
    font-size: 7px;
  }
  .rank-alp {
    font-size: 7px;
  }
  .rank-column-header {
    width: 20px;
    font-size: 6px;
  }
}
@media (max-width: 280px) {
  .rank-agent-name {
    width: 100px;
    font-size: 6px;
  }
  .rank-alp {
    font-size: 6px;
  }
  .rank-column-header {
    width: 10px;
    font-size: 5px;
  }
}

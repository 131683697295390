/* Transparent background for the navbar */
.navbar-sticky {
    position: sticky;
    top: 0;
    z-index: 1030;
    background-color: #ffffff00;
    color: #00558c;
    transition: background-color 0.3s ease-in-out;
    max-width: 100%;
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space items out evenly */
    padding: 0 10px; /* Add some horizontal padding */
}

/* Navbar brand styling */
.navbar-sticky .navbar-brand img {
    max-height: 50px;
    border: 2px solid #00548c96;
    border-radius: 50%;
}

.navbar-sticky .navbar-brand {
    position: relative; /* Enable positioning for the pseudo-element */
    display: inline-block; /* Ensure proper alignment */
    flex-shrink: 0; /* Prevent logo from shrinking */
}

/* Orange line for the active logo */
.navbar-brand.active-logo::after {
    content: ''; /* Empty content for the line */
    position: absolute;
    bottom: -5px; /* Adjust to position the sliver just below the logo */
    left: 50%;
    transform: translateX(-50%); /* Center the line */
    width: 10px; /* Adjust for sliver width */
    height: 4px; /* Sliver height */
    background-color: orange; /* Line color */
    border-radius: 2px;
}

/* Navbar tools container (for Phone Scripts & Launch Presentation) */
.navbar-tools {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 20px; /* Adjust spacing before Agent Sites */
}

/* Buttons for Phone Scripts and Launch Presentation */
.navbar-tool-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 10px;
    color: #00558c;
    font-family: Avenir Next LT Pro, Arial, sans-serif;
    font-weight: bold;
    background-color: transparent;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
}

/* Hover Colors */
.navbar-tool-button-orange:hover { /* Phone Scripts */
    color: #fba472; /* Same as main-orange-button hover */
    background-color: transparent;
}

.navbar-tool-button-green:hover { /* Launch Presentation */
    color: #218838; /* Same as main-green-button hover */
    background-color: transparent;
}

/* Active Phone Scripts Button */
.navbar-tool-button-orange.active {
    position: relative;
    font-weight: bold;
}

/* Active Launch Presentation Button */
.navbar-tool-button-green.active {
    position: relative;
    font-weight: bold;
}

/* Flashing underline animation with gradient */
.navbar-tool-button-orange.active::after,
.navbar-tool-button-green.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, rgba(255, 165, 0, 0.3) 0%, orange 50%, rgba(255, 165, 0, 0.3) 100%);
    background-size: 200% 100%;
    border-radius: 4px;
    animation: slide-underline 3s linear infinite;
}

/* Sliding effect from left to right */
@keyframes slide-underline {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}


/* Divider between buttons */
.navbar-divider {
    color: #ccc;
    font-weight: bold;
}


@media screen and (max-width: 1030px) {
    /* Buttons for Phone Scripts and Launch Presentation */
.navbar-tool-button {
    font-size: 12px;
    padding: 1px 3px;
}
}

/* Hide Launch Presentation on Mobile */
@media screen and (max-width: 800px) {
    .navbar-tool-button-green { /* Hides Launch Presentation */
        display: none;
    }
    .navbar-divider { /* Hides the divider */
        display: none;
    }
    .navbar-tool-button-orange { /* Adjusts Phone Scripts */
        margin-right: 18px;
    }
}

.dropdown-categories {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align links to the left */
    gap: 20px; /* Add spacing between links */
    flex-grow: 1; /* Push any other elements to the right */
    margin-left: 20px;
}

/* Adjust dropdown on mobile */
.dropdown {
    position: relative;
    margin-right: 15px;
    text-align: center;
    color: #00558c;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.dropdown-header {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #00558c;
    text-transform: uppercase;
    font-family: 'Avenir Next LT Pro', Arial, sans-serif;
  }

.dropdown-header:hover {
    color: #003d66;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff; /* Adjust if needed */
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 100px; /* Ensure enough space for dropdown items */
    white-space: nowrap; /* Prevent text from wrapping */
    text-align: left; /* Align content to the left */
    border-bottom: 1px solid #ababab79;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.navbar-dropdown-item {
    color: #00558c;
    text-decoration: none;
    padding: 10px 10px;
    display: block;
    position: relative;
    text-align: left;
    font-size: 12px;
    font-family: 'Avenir Next LT Pro', Arial, sans-serif;
  }

/* Line beneath each dropdown item */
.navbar-dropdown-item::after {
    content: '';
    display: block;
    width: 90%; /* Span 90% of the menu */
    height: 1px; /* Thin line */
    background-color: #e0e0e0; /* Light gray for visual separation */
    margin: 5px 0 0; /* Add spacing above and below the line */
    position: absolute;
    left: 0px; /* Align line to the left */
    bottom: 0; /* Position it at the bottom of the item */
}

.navbar-dropdown-item:last-child::after {
    content: none;
}
.navbar-dropdown-item:hover {
    background-color: #f0f8ff; /* Slightly different background color on hover */
    color: #003d66; /* Darker blue on hover */
    border-radius: 5px;
}
/* Active category styling with orange underline */
.active .dropdown-header {
    position: relative;
    color: #00558c;
}

.active .dropdown-header::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    height: 2px;
    background-color: orange;
    border-radius: 4px;
}

/* Orange bar under active category */
.active-category .dropdown-header {
    position: relative;
}

/* Orange bar under active category */
.active-category .dropdown-header::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Position the bar slightly below the header */
    left: 50%; /* Start at the center of the category */
    transform: translateX(-50%); /* Center-align the bar */
    width: 100%; /* Set the width to 15px */
    height: 2px; /* Set the height for the bar */
    background-color: orange; /* Set the orange color */
    border-radius: 2px; /* Add rounded corners */
}

/* Account Icon Styling */
.account-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto; /* Push it to the far right */
    cursor: pointer;
}

/* Account Icon Styling */
.account-icon {
    position: relative; /* Needed for positioning the hover menu */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
}

.account-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #00558c;
}

.default-profile-icon {
    font-size: 40px;
    color: #00558c;
}

.account-menu {
    display: none; /* Hidden by default */
    position: absolute;
    top: 41px; /* Place below the icon */
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 120px;
}

.account-menu.open {
    display: block; /* Shown only when open */
}


.account-menu-item {
    padding: 10px 15px;
    color: #00558c;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Avenir Next LT Pro', Arial, sans-serif;
}

.account-menu-item:hover {
    background-color: #f0f8ff;
    color: #003d66;
}

/* Show agent-sites-dropdown on desktop */
.agent-sites-dropdown {
    display: block; /* Visible on larger screens */
}

/* Hide agent-sites-dropdown on mobile screens */
@media (max-width: 768px) {
    .agent-sites-dropdown {
        display: none; /* Hidden on mobile screens */
    }
}


/* Responsive adjustments for small screens */
@media (max-width: 768px) {
    .navbar-sticky {
        flex-direction: row; /* Keep items in a single row */
    }

    .navbar-brand img {
        max-height: 40px; /* Scale down the logo for smaller screens */
    }

    .dropdown-categories {
        gap: 5px; /* Reduce spacing between links */
        margin-left: 10px; /* Adjust margin for smaller screens */
    }

    .dropdown-content {
        font-size: 12px; /* Smaller text for dropdown items */
        padding: 5px; /* Adjust padding */
    }

    .dropdown-header {
        font-size: 12px; /* Smaller text for dropdown headers */
    }
    .account-icon, 
    .presentation-button,
    .phone-button {
        display: none;
    }
}
.hamburger {
    display: none; /* Hidden by default */
    cursor: pointer;
    font-size: 1.5rem;
    color: #00558c;
    margin-left: auto; /* Push it to the far right */
    padding: 0; /* Remove extra padding */
}

.hamburger:hover {
    color: #003d66;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2000;
}

.hamburger-category {
    font-weight: bold;
    font-size: 14px;
    color: #108c00;
    text-align: left;
    margin-bottom: 5px;
    border-bottom: 1px solid #ababab79;

}

.hamburger-category a {
    display: block;
    text-decoration: none;
    color: #00558c;
    padding: 5px 10px;
    text-align: left;
    position: relative; /* Enable positioning for the line */
}

/* Line beneath each hamburger menu link */
.hamburger-category a::after {
    content: '';
    display: block;
    width: 90%; /* Span 90% of the menu */
    height: 1px; /* Thin line */
    background-color: #e0e0e0; /* Light gray for visual separation */
    margin: 5px 0 0; /* Add spacing above and below the line */
    position: absolute;
    left: 10px; /* Align line to the left */
    bottom: 0; /* Position it at the bottom of the item */
}

/* Hover effect for hamburger menu links */
.hamburger-category a:hover {
    background-color: #f0f8ff; /* Slightly different background color on hover */
    color: #003d66; /* Darker blue on hover */
}

.hamburger-category a:hover::after {
    background-color: #00558c; /* Change line color on hover */
}
/* Show hamburger menu on mobile */
@media (max-width: 768px) {
    .dropdown-categories {
        display: none; /* Hide dropdown categories */
    }

    .hamburger {
        display: block; /* Show hamburger menu icon */
        position: absolute; /* Align it properly */
        top: 50%; /* Center it vertically relative to the navbar */
        right: 10px; /* Align to the right edge with a small margin */
        transform: translateY(-50%); /* Adjust for vertical centering */
    }
}


/* Highlight styles for the top 3 rows */
.more-top-1-row {
    background-color: #ffe574; /* Gold background */
    font-weight: bold;
    font-size: 1.1rem;
}

.more-top-2-row {
    background-color: #c0c0c0; /* Silver background */
    font-weight: bold;
    font-size: 1.05rem;
}

.more-top-3-row {
    background-color: #cd7f32; /* Bronze background */
    font-weight: bold;
    font-size: 1.05rem;
}

/* Total Hires styles */
.more-total-hires-green {
    color: #4caf50; /* Green for high hires */
    font-weight: bold;
}

.more-total-hires-yellow {
    color: #000000; /* Yellow for moderate hires */
    font-weight: normal;
}

.more-total-hires-red {
    color: #f44336; /* Red for low hires */
    font-weight: normal;
}

/* General table styles */
.more-leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}



.more-leaderboards-section {
    margin: 20px auto;
    padding: 15px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.more-card-title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 10px;
}

/*./src/components/Dashboard/Dashboard.css*/

/* Container for the entire content */
.content-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: flex-start; /* Align items at the start of the container */
    width: 100%;
}

/* Container for left column (cards + new section) */
.left-column-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between stacked sections */
}

/* Styling for the new section */
.trophy-container {
    padding: 15px;
    border-radius: 25px;
    background-color: #d6d6d680;
}

/* Ensure stacking on smaller screens */
@media (max-width: 768px) {
    .left-column-container {
        width: 100%;
    }
}


/* Container for cards section */
.cards-container {
    padding: 15px;
    border-radius: 25px;
    background-color: #d6d6d680;
}

/* Container for leaderboards section */
.leaderboards-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border-radius: 25px;
    background-color: #d6d6d680;
}
.date-picker-and-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
/* Media query for smaller screens */
@media (max-width: 768px) {

    .header-with-toggle-and-filters {
        flex-direction: column;
        align-items: stretch;
    }

    .date-picker-and-filter-group {
        width: 100%;
        justify-content: space-between;
    }

    .tabs {
        width: 100%;
        justify-content: space-between;
    }
    
}

.cards-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.card {
    position: relative;
    flex: 1 1 300px; /* Make sure cards have a minimum width and are flexible */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin-bottom: 10px;
    padding: 5px;
    padding-bottom: 25px;
    min-width: 100px;
    max-width: 250px; /* Define a max width for each card */
}
.insight {
    position: absolute;
    bottom: 5px;
    left: 15px;
    font-size: 12px;
    color: rgb(5, 144, 28);
    transition: opacity 0.3s ease;
    opacity: 0; /* Initially hidden */
}

.insight.negative {
    color: rgb(255, 0, 0);
}
.card:hover .insight {
    opacity: 1;
}
/* Media queries to adjust the number of cards per row based on container size */
@media (min-width: 300px) and (max-width: 599px) {
    .cards-container {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .card {
        flex: 1 1 calc(50% - 40px); /* 2 cards per row */
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .cards-container {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .card {
        flex: 1 1 calc(50% - 40px); /* 2 cards per row */
    }
    .card-title {
        font-size: 18px;
    }

}

@media (min-width: 900px) {
    .cards-container {
        flex-wrap: wrap;
    }
    .card {
        flex: 1 1 calc(33.33% - 40px); /* 3 cards per row */
    }

}

.card-title {
    margin: 5px 0 10px 10px;
    font-size: 15px;
    text-align: left;
}

.card-text {
    margin: 0px 0 10px 10px;
    font-size: 18px;
    text-align: left;
}

.big-card-text {
    margin: 0px 0 0px 10px;
    font-size: 20px;
    text-align: left;
}

.insight-card-text, .lower-card-text {
    position: absolute;
    bottom: 1px;
    margin: 0px 0 5px 10px;
    transition: opacity 0.3s ease;
}

.insight-card-text {
    color: rgb(5, 144, 28);
    opacity: 1;
}

.lower-card-text {
    color: rgb(128, 128, 128);
    opacity: 0;
    font-size: 14px;
}

.card:hover .insight-card-text {
    opacity: 0;
}

.card:hover .lower-card-text {
    color: black;
    opacity: 1;
}
.header-with-toggle-and-filters {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.date-picker-container {
    position: relative;
}

.date-picker-group {
    position: relative;
    margin-right: 10px;
}
.date-picker-and-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.date-picker-overlay {
    position: absolute;
    top: calc(100% + 5px); /* Adjusts the top position to give some space between the button and the overlay */
    left: 0; /* Aligns the overlay to the right side of the button */
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    min-width: 300px; /* Ensures a minimum width */
    max-width: 100vw; /* Ensures it doesn't exceed the viewport width */
    overflow-x: auto;
}
.cards-row.hovered .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #e6e6e6;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.8s ease;
}


.calendar-button {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.calendar-button:hover {
    background-color: #e6e6e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar-button i {
    margin-right: 8px;
}

.calendar-button span {
    white-space: nowrap;
}

.date-range-display {
    margin-bottom: 5px;
    text-align: center;
    font-size: 20px;
}

.date-range-display span:first-child {
    font-weight: bold;
    color: #333;
}

.date-range-display span:last-child {
    color: #666;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dashboard-button {
    padding: 10px 15px;
    font-size: 1rem;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    text-decoration: none;
}

.dashboard-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.main-button {
    font-size: 1.25rem;
    padding: 12px 20px;
    background-color: #28a745;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.agent-select-container {
    width: 100%;
    margin-bottom: 5px;
}

.mga-rga-row {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.select-container {
    width: 100%;
}

.date-picker-and-agent-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.agent-select-container {
    margin-top: 15px;
    width: 48.5%;
}

.selection-overlay {
    margin: 20px 0;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    color: #333;
}

.toggle-classic-view {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.toggle-icon {
    scale: 2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s ease;
}

.toggle-icon.open {
    transform: rotate(90deg);
}

.toggle-classic-view:hover .toggle-icon {
    transform: scale(1.2) rotate(45deg);
}

.week-view-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.week-view-header {
    margin: 0;
    font-size: 20px;
}

.open .classic-view-container {
    max-height: 100vh;
}

.classic-view-container {
    overflow: hidden;
    transition: max-height 0.75s ease-out, opacity 0.5s ease-out;
    max-height: 0;
    opacity: 0;
}

.classic-view-container.open {
    max-height: 3825px;
    opacity: 1;
}

.toggle-display-mode-button {
    display: none;
}

/* Medium screens - 3 cards per row */
@media (max-width: 1024px) {
    .card {
        flex-basis: calc(33.33% - 20px);
    }
}

/* Small screens - 2 cards per row */
@media (max-width: 768px) {
    .card {
        flex-basis: calc(50% - 20px);
    }
}

/* Extra small screens - 1 card per row */
@media (max-width: 480px) {
    .card {
        flex-basis: calc(50% - 20px);
    }
    .card-title {
        font-size: 14px;
    }
    .card-text {
        font-size: 16px;
    }
    .main-button {
        display: none;
    }
    .toggle-display-mode-button {
        display: inline-block;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #000;
    }

    .date-picker-and-agent-row {
        flex-direction: column;
        align-items: stretch;
    }
    
    .calendar-button {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dropdown-container,
    .agent-select-container, 
    .mga-rga-row .select-container {
        width: 100%;
    }

    .agent-select-container {
        margin-bottom: 10px;
    }

    .mga-rga-row {
        flex-direction: column;
        gap: 10px;
    }
    h4 {
        font-size: 20px !important;
    }
    .cards-container {
        gap: 5px;
    }
}

@media (min-width: 481px) {
    .date-picker-and-agent-row {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .dropdown-container {
        display: flex;
    }
    
    .dropdown-container,
    .agent-select-container, 
    .mga-rga-row .select-container {
        width: 100%;
    }
    
    .agent-select-container, 
    .mga-select-container, 
    .rga-select-container {
        flex: 1;
    }
}

/* Always hide presets initially on mobile screens */
@media (max-width: 480px) {
    .rdrDefinedRangesWrapper {
      display: none !important;
    }
}

/* When the .show-presets class is added, hide the calendar and show the presets */
@media (max-width: 480px) {
    .show-presets .rdrDefinedRangesWrapper {
      display: block !important;
    }

    .show-presets .rdrCalendarWrapper {
      display: none !important;
    }
}


.update-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.update-message-box {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 80%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.update-message-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    margin-top: 15px;
    border-radius: 5px;
    margin-bottom:15px;
}

.update-message-close {
    margin-top: 15px;
    padding: 10px 15px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.update-message-close:hover {
    background: #0056b3;
}

.adobe-sign-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark background to indicate modal */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.adobe-sign-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    max-width: 1200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}
.verification-form-container {
    max-width: 600px;
    margin: 0 auto;
}

.client-info-group h4 {
    font-family: 'Avenir Next LT Pro', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #00558c;
}
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
}

.input-group label {
    margin-bottom: 5px;
    font-family: 'Calibri', sans-serif;
}

.input-group input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Calibri', sans-serif;
}

.input-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
}

.input-row .input-group {
    flex: 1;
}

.phone-number-container {
    display: flex;
    gap: 10px;
    width: 100%;
}

.phone-number-container input {
    width: 33%; /* Ensures all phone number parts are equally distributed */
}

.agent-info-group .input-row {
    display: flex;
    gap: 20px;
}

.agent-info-group .input-group {
    flex: 1;
}

/* Responsive Design */
@media (max-width: 600px) {
    .input-row {
        flex-direction: column;
        gap: 10px;
    }

    .phone-number-container input {
        width: 100%; /* Make phone number inputs stack on smaller screens */
    }
}
/* Additional Styles for Insured Info Component */

/* Centering the insured buttons */
.insured-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 20px;
}

.insured-button {
    background-color: #00558c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    margin: 0 5px; /* Adds space between buttons */
    font-family: 'Calibri', sans-serif;
}

.insured-button:hover {
    background-color: #00407a; /* Darken on hover */
}

/* Aligning labels and checkboxes in a row */
.insured-label-checkboxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust spacing between label and inputs */
    gap: 10px; /* Adds space between the label and checkboxes */
    
}

.insured-label {
    font-weight: bold;
    flex-shrink: 0; /* Prevents the label from shrinking */
    margin-right: 15px;
}

.checkbox-group {
    display: flex;
    gap: 15px; /* Adds space between checkboxes */
}

/* Inputs in a row for first name, last name, and premium */
.insured-group .input-group {
    display: flex;
    gap: 10px;
}

.modern-input,
.premium-input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.premium-input {
    max-width: 150px; /* Set a maximum width for premium inputs */
}



/* Premium Information Section */
.premiums-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.premium-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.premium-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0 10px;
}

.premium-item label {
    margin-bottom: 5px;
}

.premium-item input {
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 12px;
    box-sizing: border-box;
    font-family: 'Calibri', sans-serif;
}

/* Remove spinners from number input fields */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* Responsive Design for Premium Inputs */
@media (max-width: 600px) {
    .premium-row {
        flex-direction: column;
        gap: 15px;
    }
}

.morefilters-container {
  position: relative;
  font-size: 12px;
  margin-bottom: 20px;
}

.morefilters-collapse-button {
  background-color: #00548c;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.morefilters-collapse-button:hover {
  background-color: #57d46d;
}

.morefilters-section {
  background-color: transparent;
  border-radius: 8px;
  padding: 15px;
}

.morefilters-filter-group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Add space between the items */
  width: 100%;
}

.morefilters-filter-group {
  flex: 1; /* Make filter groups take equal space */
}

.morefilters-filter-group.label-only {
  flex: 0; /* Allow only the label to take its natural width */
}

.dropdown-with-navigation {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between arrows and dropdown */
  flex-grow: 1; /* Allow the dropdown to take up remaining space */
  width: 100%;
}

.morefilters-period-value-select {
  flex-grow: 1; /* Allow the dropdown itself to grow */
  width: 100%;
  min-width: 300px; /* Prevent unwanted minimum width issues */
}

.more-week-button {
  background-color: transparent;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.more-week-button:hover {
  color: rgb(204, 204, 204);
}

.morefilters-period-type-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.morefilters-period-button {
  background-color: #e0e0e0;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.morefilters-period-button.active {
  background-color: #00548c;
  color: white;
}

.morefilters-period-button:hover {
  background-color: #57d46d;
}

.morefilters-collapse-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.morefilters-collapse-header .arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.morefilters-collapse-header.collapsed .arrow {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}


@media (max-width: 768px) {
  .morefilters-filter-group-row {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Ensure full width */
  }

  .filter-group-inline {
    margin-right: 0; /* Remove side margins */
    margin-bottom: 10px; /* Add spacing between stacked items */
    width: 100%; /* Ensure full width */
  }

  .dropdown-with-navigation {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between arrows and dropdown */
    flex-grow: 1; /* Ensure the dropdown stretches */
    width: 100%; /* Ensure full width for smaller screens */
  }

  .more-week-button {
    flex-shrink: 0; /* Prevent the arrows from shrinking */
  }

  .morefilters-period-value-select {
    flex-grow: 1; /* Allow the dropdown to take up the remaining space */
    width: 100%; /* Ensure the dropdown stretches properly */
    min-width: 0; /* Prevent unwanted minimum width issues */
  }
}

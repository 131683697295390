/* src/components/Recruiting/Recruiting.css */
.pipeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-buttons {
  margin: 20px;
  display: flex;
  gap: 10px;
}

.view-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  border-radius: 20%;
}

.view-button img {
  width: 24px;
  height: 24px;
}


.view-button.active {
  background-color: #00558c !important;
  border-radius: 20%;
  color: white;
}

.view-button:hover {
  background-color: #555555;
  border-radius: 20%;
}

  
  .view-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .recruits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .recruit-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 150px;
    text-align: center;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .navigation-arrows {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  
  .navigation-arrows button {
    background: none;
    border: none;
    font-size: 18px;
    color: #7e7e7e;
    cursor: pointer;
    padding: 5px;
    outline: none;
    transition: color 0.3s;
  }
  
  .navigation-arrows button:hover {
    color: #000; /* Darken color on hover for better visibility */
  }
  
/* Kanban view container */
.kanban-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling if necessary */
  padding: 20px;
  box-sizing: border-box;
}

/* Kanban column styling */
.kanban-column {
  flex: 0 0 20%; /* Adjust the percentage or use a fixed width if necessary */
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  min-width: 200px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.kanban-column:last-child {
  margin-right: 0;
}

.kanban-column h3 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.recruit-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.recruit-item:hover {
  background-color: #e9e9e9;
}
.kanban-column-hover {
  background-color: #e0f7fa; /* Light blue background */
  border: 2px dashed #00796b; /* Dashed border to indicate drop zone */
}

.recruit-card.dragging {
  opacity: 0.5; /* Lower opacity when dragging */
  transform: scale(1.05); /* Slightly enlarge the card */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.toggle-form-button {
  background-color: #00796b;
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggle-form-button:hover {
  background-color: #004d40;
}

/* Main container */
.leaderboard-placeholder {
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    height: 400px; /* Same as the leaderboard height */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    animation: fadeIn 0.5s ease-in-out;
}

/* Title placeholder */
.leaderboard-placeholder-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    height: 20px;
    width: 40%;
    animation: placeholderAnimation 1s infinite linear;
    border-radius: 4px;
}

/* Rows placeholder */
.leaderboard-placeholder-rows {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Individual placeholder row */
.placeholder-row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
}

/* Avatar placeholder */
.placeholder-avatar {
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    animation: placeholderAnimation 1s infinite linear;
}

/* Details placeholder */
.placeholder-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Shorter placeholder line for the name */
.placeholder-line.short {
    width: 60%;
    height: 12px;
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    animation: placeholderAnimation 1s infinite linear;
    border-radius: 4px;
}

/* Default placeholder line for the other details */
.placeholder-line {
    width: 80%;
    height: 12px;
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    animation: placeholderAnimation 1s infinite linear;
    border-radius: 4px;
}

/* ALP placeholder */
.placeholder-alp {
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    height: 20px;
    width: 60px;
    border-radius: 4px;
    animation: placeholderAnimation 1s infinite linear;
}

/* Animation for the placeholder shimmer effect */
@keyframes placeholderAnimation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

/* Fade-in animation for the leaderboard container */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

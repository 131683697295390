.content {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.rank-leaderboard-card {
    flex: 1;
    min-width: 200px;
    max-width: 100%;
}
.header-tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .header-tabs-container {
        flex-direction: column;
        align-items: flex-start;
    }

}

@media (min-width: 901px) {
    .rank-leaderboard-card {
        flex: 1 1 calc(25% - .75rem);
    }
}

@media (max-width: 1400px) and (min-width: 601px) {
    .rank-leaderboard-card {
        flex: 1 1 calc(50% - .75rem);
    }
}

@media (max-width: 720px) {
    .rank-leaderboard-card {
        flex: 1 1 100%;
    }
}
.header-with-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
}

.tabs {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    margin-bottom: 10px;
}

@media (min-width: 500px) {
    .header-with-toggle {
        flex-direction: row;
        justify-content: space-between;
    }

    .tabs.period-tabs {
        margin-bottom: 0;
    }

    .tabs.data-tabs {
        margin-bottom: 0;
    }
}

.separate-container {
    margin-top: .5rem;
    width: 100%;
}

.rank-leaderboard-card h5 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

/* Container */
.more-leaderboard-container {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    font-family: Avenir Next LT Pro;
  }
  
  
  .more-table-container {
    margin-top: 20px;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling for wider tables */
    border: 2px solid #ddd; /* Add border for better visibility */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
  }


/* Profile Picture Container */
.more-profile-pic {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* White background for spacing */
  border-radius: 50%; /* Ensures a round border */
  padding: 1px; /* Space between the picture and the border */
  border: 1px solid #6582b2a1; /* Outer border */
  margin-bottom: 5px;
  margin-top: 5px;
  width: 50px;
  height: 50px;
}

/* Placeholder for missing profile picture */
.profile-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd; /* Background color for placeholder */
  color: #555; /* Text color */
  font-size: 20px; /* Font size for the letter */
  font-weight: bold;
  width: 50px; /* Same width as profile picture */
  height: 50px; /* Same height as profile picture */
  border-radius: 50%; /* Make it circular */
  text-transform: uppercase; /* Ensure the letter is uppercase */
  padding: 2px; /* Space between the picture and the border */
  border: 1px solid #6582b2a1; /* Outer border */
}

  .more-agent-name {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Table Section */
  .more-table-container {
    margin-top: 20px;
  }
  
  .more-leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    text-align: left;
  }
  
  .more-leaderboard-table th,
  .more-leaderboard-table td {
    padding: 5px;
  }

  .more-leaderboard-table th {
    background-color: #ffffff;
    font-weight: bold;
    color: black;
  }
  
  .more-leaderboard-table tr:nth-child(even) {
    background-color: #ffffff;
  }

  .more-leaderboard-table tr:nth-child(odd) {
    background-color: #f7f7f7;
  }
  .more-leaderboard-table td:nth-child(2) {
    display: flex;
    justify-content: flex-end; /* Push the content to the right */
    align-items: center; /* Vertically center the image */
  }
  
  .more-leaderboard-table td:nth-child(3) {
  text-align: left; /* Align text to the left */
  font-size: 18px;
}

.more-leaderboard-table th:nth-child(3) {
  text-align: left; /* Align text to the left */
}
.more-leaderboard-table td:nth-child(3),
.more-leaderboard-table td:nth-child(4) {
  font-size: 16px; 
  font-weight: bold;
}


  .more-no-data-message {
    text-align: center;
    font-size: 1.2rem;
    color: #777;
    margin: 20px 0;
  }
  
  .profile-name-container {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Add space between the image and the name */
  }

  @media (max-width: 768px) {
    .more-leaderboard-table td:nth-child(2)
     {
      padding-left: 0px;
      
    }
    .more-leaderboard-table th:nth-child(2) {
      padding-left: 20px;
    }
    .more-profile-pic {
      width: 30px;
      height: 30px;
    }
    .profile-placeholder {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
    .more-agent-name {
      font-size: 0.6rem;
    }
    .more-leaderboard-table td:nth-child(3),
.more-leaderboard-table td:nth-child(4) {
  font-size: 12px; 
  font-weight: bold;
}
.more-leaderboard-table td:nth-child(1) {
  font-size: 10px; 
  font-weight: bold;
}
.more-leaderboard-table th {
  font-size: 10px;
}
  }

  .more-highlighted-row {
    font-weight: bold !important;
    color: orange !important;
  }
  
.growth-data-container {
    overflow-x: auto; /* Enables horizontal scrolling */
  }

.growthcard {
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.149);
    border: 1px solid hsla(0, 0%, 0%, 0.279);
    backdrop-filter: blur(15px);
    border-radius: 14px;
    background: hsl(0, 0%, 100%);
    color: rgb(0, 0, 0);
    transition: box-shadow 0.5s;
    height: 90px;
    flex: 1;
    min-width: 0;
}

.growthcards-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.growth-table-input-like {
    border: 2px solid #d1d5db;
    padding: 3px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
}

.growth-table-header, .green-growth-table-header, .grey-table-header {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 4px;
    min-width: 60px;
}

.growth-table-header {
    background-color: #00548c;
}

.green-growth-table-header {
    background-color: #37623e;
}

.grey-table-header {
    background-color: #474c55;
}
.top-table-second-header {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #4c6851;
}
.growth-data-container {
    overflow-x: auto;
    max-height: 700px; /* Or whatever height you prefer */
    overflow-y: auto;}

.growth-table-header, .growth-table-input-like {
    position: sticky;
    z-index: 2;
}

/* Add this to your MoreDataTable.css */
.growth-header-container {
    display: flex; /* This will align child elements in a row */
    justify-content: space-between; /* This will space the child elements at the start and end of the container */
    align-items: center; /* This will vertically align the child elements in the center */
    margin-bottom: 5px; /* This will add space between the header and the table */
}
@media (max-width: 650px) {
    .growthcard {
        flex-basis: 100%;
    }
}

.growthbonus-table tr:nth-child(even) td {
    background-color: #ffffff;
}

.growthbonus-table tr:nth-child(odd) td {
    background-color: #f1f1f1;
}



.growth-table-first-col {
    left: 0;
    z-index: 3; /* Ensures it stays above other cells when scrolling horizontally */
}
.growth-table-thead {
    position: sticky;
    top: 0;
    z-index: 100; /* Ensure the header is above other content */
}


.growth-data-container:hover::-webkit-scrollbar-thumb {
    background-color: #c0c0c0; /* Changes the thumb color on hover */
}


.flex-row-container {
    display: flex; /* This will enable Flexbox for the container, placing child elements in a row */
    align-items: center; /* This will vertically center the child elements within the container */
    margin-bottom: 10px;
}


.toggle-growth-view {
    margin-left: 10px; /* Adds some space between the text and the icon */
    display: flex; /* This ensures that the icon aligns properly with the text */
    align-items: center; /* Centers the icon vertically with respect to the text */
    scale: 0.7;
}

.toggle-icon {
    transition: transform 0.3s ease; /* Smooth transition for rotating icon */
}

.toggle-icon.open {
    transform: rotate(90deg); /* Rotate icon when the section is open */
}
/* Animation on hover */
.toggle-growth-view:hover .toggle-icon {
    transform: scale(1.2); /* Enlarge the icon */
    transform: rotate(45deg);
}

.first-row-header {
    min-width: 170px;
    color: red;
    background-color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 4px;
    min-width: 60px;
}

.second-row-header {
    min-width: 170px;
    color: black;
    background-color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 4px;
    min-width: 60px;
}

.third-row-header {
    min-width: 170px;
    color: red;
    background-color: #D8E4BC;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 4px;
    min-width: 60px;
}

.first-row-cells {
    border: 2px solid #d1d5db;
    padding: 3px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    color: red;
}

.second-row-cells {
    border: 2px solid #d1d5db;
    padding: 3px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    color: black;
}

.third-row-cells {
    border: 2px solid #d1d5db;
    padding: 3px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #D8E4BC;
    font-size: 12px;
    color: red;
}

.qtd-column-cells {
    border: 2px solid #d1d5db;
    padding: 3px;
    text-align: center;
    background-color: #DCE6F1;
    font-size: 12px;
    color: black;
}

.total-column-cells {
    border: 2px solid #d1d5db;
    padding: 3px;
    text-align: center;
    background-color: #FFFF99;
    font-size: 12px;
}


.codesTable-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    gap: 20px; /* Adds space between tables */
}

.codesTable-container table {
    width: calc(50% - 10px); /* Adjusts table width to account for gap */
    border-collapse: collapse;
    margin-bottom: 20px;
}

.codesTable-container th,
.codesTable-container td {
    border: 1px solid #e1e1e1; /* Light grey border */
    padding: 10px; /* Padding for content */
    text-align: left;
}

.codesTable-container thead th {
    background-color: #f7f7f7; /* Light grey background for header */
    color: #333; /* Dark grey text for contrast */
}

.codesTable-container tbody tr {
    min-height: 50px; /* Ensures a consistent row height */
    display: table-row; /* Ensures that tr behaves as a row */
}

.codesTable-container tbody td {
    vertical-align: middle; /* Centers content vertically */
}

.codesTable-container tbody tr:hover {
    background-color: #f0f0f0; /* Slightly darker background on hover */
}

.codesTable-container tbody tr:nth-child(odd) {
    background-color: #fafafa; /* Very light grey for every other row */
}
.tables-container {
    display: flex;
    flex-direction: row; /* Align children (table wrappers) in a row */
    gap: 20px; /* Adds space between tables */
    width: 100%;
    overflow-x: auto; /* Allows horizontal scrolling if tables overflow the viewport */
}

.table-wrapper {
    flex: 1; /* Makes each table wrapper flexibly grow to fill the container */
    width: 50%; /* Suggests an initial width, but flex-grow adjusts the final size */
    overflow-x: auto; /* Allows scrolling within each table if content overflows */
}

/* Additional CSS for table styling */
.table-wrapper table {
    width: 100%; /* Makes table take full width of its container */
    border-collapse: collapse;
}

.table-wrapper th, .table-wrapper td {
    border: 1px solid #e1e1e1;
    padding: 10px;
    text-align: left;
}

.table-wrapper thead th {
    background-color: #f7f7f7;
    color: #333;
}

.table-wrapper tbody tr:hover {
    background-color: #f0f0f0;
}

.table-wrapper tbody tr:nth-child(odd) {
    background-color: #fafafa;
}

/*./src/components/Dashboard/Cards.css*/

.activity-card {
    flex: 1; /* Allows cards to grow equally */
    max-width: 100%; /* Prevent cards from exceeding container width */
    text-align: left;
    padding: 10px;
    position: relative;
    box-sizing: border-box; /* Ensure padding and borders don't affect size */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: ease 0.3s;
    font-family: Avenir Next LT Pro;
  }
  
  .activity-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .activity-cards-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    margin: 15px 0;
    font-family: Avenir Next LT Pro;
  }
    
  .activity-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    
  .activity-card-header h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    padding-top: 10px;
    padding-left: 5px;
  }
  .activity-card-title {
    font-weight: bold;
  }
  .activity-donut-container {
    position: absolute;
    top: 15px;
    right: 15px;
  }
    
  .activity-donut-chart {
    width: 60px;
    height: 60px;
  }
    
  .activity-donut-ring {
    stroke-width: 3;
  }
    
  .activity-donut-segment {
    stroke-width: 3;
    transition: stroke-dasharray 0.5s;
  }
    
  .activity-card-body {
    margin-top: 50px;
    text-align: center;
  }
  
  .activity-value {
    font-size: 2.2rem;
    font-weight: bold;
    margin: 5px 0;
    color: #333;
  }
    
  .activity-metric {
    font-size: 0.9rem;
    color: #555;
  }
  
    /* Container for the two columns below the top cards */
.activity-columns-container {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  
  /* Each column holds 4 cards (arranged in 2 rows) */
  .activity-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* A row within each column (2 cards per row) */
  .activity-row {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  
  /* Optionally, adjust individual activity-card widths for the two-column layout */
  .activity-column .activity-card {
    flex: 1;
    max-width: calc(50% - 5px);  /* Two cards per row with a small gap */
  }
  /* Styles for the top row (ALP and Ref ALP cards) */
.top-cards-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;      /* Prevent cards from wrapping on smaller screens */
    justify-content: space-between;
    gap: 10px;              /* Adjust the gap between the cards as needed */
    margin-bottom: 20px;    /* Space below the top row */
  }
  
  /* Ensure each card in the top row takes equal available space */
  .top-cards-row {
    flex: 1;
    /* Optionally, you can set a minimum width if desired */
    min-width: 0;
  }
  .activity-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  /* Activity Divider: a vertical grey line between columns */
.activity-divider {
    width: 2px;                /* Thin line */
    background-color: #ccc;    /* Grey color (adjust as needed) */
    align-self: stretch;       /* Stretch to fill the container's height */
    margin: 0 2px;            /* Optional horizontal spacing between columns */
  }
  
  @media (max-width: 1308px) {
    .activity-card {
      width: 100%;
      text-align: center; /* Center content on smaller screens */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .activity-card-header {
      flex-direction: column; /* Stack header elements vertically */
      align-items: center;
      gap: 10px;
    }
  
    .activity-card-header h5 {
      font-size: 0.8rem; /* Adjust font size for smaller screens */
    }
  
    .activity-value {
      font-size: 0.8rem; /* Adjust font size for smaller screens */
      order: -1; /* Keep value above the donut chart */
    }
  
    .activity-metric {
      font-size: 0.6rem; /* Adjust font size for smaller screens */
    }
  
    .activity-donut-container {
      position: static; /* Remove absolute positioning */
      order: 1; /* Move below the activity value */
    }
  
    .activity-donut-chart {
      width: 50px; /* Adjust size for smaller screens */
      height: 50px;
    }
  
    .activity-card-body {
      margin-top: 20px; /* Adjust spacing */
    }
  }
  
@media (max-width: 768px) {
    .activity-card-title {
      font-size: .8rem;
    }
    .activity-columns-container {
        gap: 5px;
    }
}
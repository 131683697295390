/* TrophyCaseModal.css */
.modal-dialog {
    margin: 1.75rem auto;
}

.text-center {
    font-family: 'Calibri', sans-serif;
}

.club-section {
    margin-bottom: 30px;
    position: relative;
}

.trophy-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to the left */
    gap: 20px;
    margin-bottom: 20px; /* Add space between card sections */
}

.trophy-card {
    flex: 1 1 calc(33.333% - 20px); /* Default: 3 cards per row */
    max-width: calc(33.333% - 20px);
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    color: black; /* Ensure text color is visible */
    background-color: white; /* Ensure the card background is white */
    position: relative;
}

.trophy-card-body {
    padding: 16px;
    position: relative;
    z-index: 1; /* Ensure content is above the background image */
}

.trophy-card-title {
    font-size: 1.25em;
    margin-bottom: 8px;
    color: black; /* Ensure the card title is black */
}

.trophy-lvl1net {
    font-size: 1.5em; /* Make the LVL_1_NET value larger */
    font-weight: bold;
}

/* Ranked message specific styling */
.ranked-message {
    font-size: 0.9em; /* Smaller font size for rank message */
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Hides any overflow */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
}

/* Bronze trophy card styles */
.bronze-trophy {
    position: relative;
    overflow: hidden; /* Ensures the pseudo-element doesn't extend outside the card */
}

.bronze-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/bronze.png') no-repeat center center;
    background-size: auto 110%;
    background-position-x: 85%; /* Adjust as needed */
    opacity: 0.5;
    z-index: 0; /* Ensure it's below the content */
}

.bronze-trophy .trophy-lvl1net {
    color: #555555; /* Bronze color */
}

/* Silver trophy card styles */
.silver-trophy {
    position: relative;
    overflow: hidden;
}

.silver-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/silver.png') no-repeat center center;
    background-size: auto 110%;
    background-position-x: 85%;
    opacity: 0.5;
    z-index: 0;
}

.silver-trophy .trophy-lvl1net {
    color: #c0c0c0; /* Silver color */
}

/* Gold trophy card styles */
.gold-trophy {
    position: relative;
    overflow: hidden;
}

.gold-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/gold.png') no-repeat center center;
    background-size: auto 110%;
    background-position-x: 85%;
    opacity: 0.5;
    z-index: 0;
}

.gold-trophy .trophy-lvl1net {
    color: #3f3f3f; /* Gold color */
}

/* Platinum trophy card styles */
.platinum-trophy {
    position: relative;
    overflow: hidden;
}

.platinum-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/platinum.png') no-repeat center center;
    background-size: auto 110%;
    background-position-x: 85%;
    opacity: 0.5;
    z-index: 0;
}

.platinum-trophy .trophy-lvl1net {
    color: rgb(53, 53, 53); /* Platinum color */
}

/* Diamond trophy card styles */
.diamond-trophy {
    position: relative;
    overflow: hidden;
}

.diamond-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/diamond.png') no-repeat center center;
    background-size: auto 110%;
    background-position-x: 85%;
    opacity: 0.5;
    z-index: 0;
}

.diamond-trophy .trophy-lvl1net {
    color: #b9f2ff; /* Diamond color */
}

/* Record month trophy card styles */
.record-trophy {
    flex: 1 1 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;

}

.record-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/trophy.png') no-repeat center center;
    background-size: auto 105%;
    background-position-x: 95%;
    opacity: 0.5;
    z-index: 0;
}

.record-trophy .trophy-lvl1net {
    color: rgb(5, 144, 28); /* Match the color of the section */
}

/* Club title container styles */
.club-title-container {
    display: flex;
    align-items: center;
}

.club-title {
    margin-right: 10px;
}

.club-icons {
    display: flex;
}

.club-icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: -8px;
    margin-bottom: 6px;
}

/* Use custom coin images for club icons */
.bronze .club-icon {
    background-image: url('../../img/bronzecoin.png');
}

.silver .club-icon {
    background-image: url('../../img/silvercoin.png');
}

.gold .club-icon {
    background-image: url('../../img/goldcoin.png');
}

.platinum .club-icon {
    background-image: url('../../img/platinumcoin.png');
}

.diamond .club-icon {
    background-image: url('../../img/diamondcoin.png');
}

.record-week-trophy {
    flex: 1 1 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;

}

.record-week-trophy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../img/calendar.png') no-repeat center center;
    background-size: auto 85%;
    background-position-x: 94%;
    opacity: 0.5;
    z-index: 0;
}

/* TrophyCaseModal.css */
.modal-body {
    min-height: 700px; /* Adjust this height as needed */
    overflow-y: auto; /* Allows scrolling if content exceeds fixed height */
}

.placeholder-trophy-card {
    height: 150px; /* Adjust heights to match the content */
    background-color: #f0f0f0;
    margin-bottom: 10px;
}
.placeholder-profile-picture {
    height: 150px; /* Adjust heights to match the content */
    width: 150px; /* Match the width to the height */
    background-color: #f0f0f0;
    margin-bottom: 10px;
    border-radius: 100%; /* Ensure the picture is rounded */
    background-image: url('../../img/silvercoin.png'); /* Set the default image */
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; /* Center the image */
}
.profile-picture {
    width: 150px; /* Set the width */
    height: 150px; /* Set the height */
    object-fit: cover; /* Ensure the image covers the entire area */
    margin-bottom: 10px; /* Space below the profile picture */
    border-radius: 100%; /* Ensure the picture is rounded */
    background-color: #f0f0f0; /* Background color in case image is missing */
}
.placeholder-bio {
    height: 100px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .trophy-card {
        flex: 1 1 calc(50% - 20px); /* 2 cards per row on medium screens */
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .trophy-card {
        flex: 1 1 100%; /* 1 card per row on small screens */
        max-width: 100%;
    }
}

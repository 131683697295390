@charset "utf-8";
body, input, select {
    font-family: "Calibri", sans-serif;
    font-size: 12pt;
}
input[type="checkbox"] {
  vertical-align: middle;
}
.pi-dobText {
  color: #000;
}
.setup-grid-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 600px;
    margin: 0 auto;
    margin-top: 10vh;
    grid-gap: 2px;
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.2);
    margin-bottom: 20vh;
}
.setup-grid-container-start {
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 600px;
    margin: 0 auto;
    grid-gap: 2px;
}
.setup-grid-item {
    background-color: rgba(0,85,140,.1);
    padding: 10px;
}
.setup-grid-item-spouse {
    background-color: rgba(237,114,47,.1);
    padding: 10px;
}
.setup-grid-item-pref {
    background-color: rgba(71,76,85,.1);
    padding: 10px;
}
.setup-grid-item-start {
    text-align: center;
    padding: 10px;
    width: 400px;
}
.setup-grid-item-agent {
    background-color: rgba(49,155,66,0.1);
    padding: 10px;
}
.setup-grid-item-left {
    background-color: rgba(0, 85, 140, 0.25);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setup-grid-item-left-spouse {
    background-color: rgba(237,114,47,.25);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setup-grid-item-left-agent {
    background-color: rgba(49,155,66,0.25);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setup-grid-item-left-pref {
    background-color: rgba(71,76,85,.25);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setup-grid-item-left-start {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setup-text, .setup-drop {
    width: 66%;
    padding: 3px;
    border: 1px solid #00558C;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}
.setup-text:focus, .setup-drop:focus, .setup-checkbox:focus, .setup-text-pi:focus, .sex-drop:focus {
    outline: none;
    border-color: #319B42;
    box-shadow: 0 0 5px #319B42;
}
.setup-text[disabled], .setup-drop[disabled], .setup-text-pi[disabled], .sex-drop[disabled] {
    pointer-events: none;
    background-color: #f2f2f2;
}
.setup-text-pi {
    width: 12ch;
    padding: 3px;
    border: 1px solid #00558C;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}
.sex-drop {
    width: 12%;
    padding: 2px 3px;
    border: 1px solid #00558C;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}
.setup-checkbox {
    width: 15px;
    height: 15px;
}
.setup-button {
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease;
    background-color: #319B42;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 12vw;
    min-width: min-content;
    margin: 2px;
}
.setup-button:hover {
    background-color: #60CC72;
}
.setup-button:active {
    background-color: #bfb9b6;
}
.group-hide {
    display: none;
}
.sponsor-hide {
    display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
    body, input, select {
        font-size: 10pt;
    }
    .setup-grid-container, .setup-grid-container-start {
        grid-template-columns: 1fr; /* Single column for mobile */
        width: 90%;
        margin-top: 5vh;
        grid-gap: 10px;
    }
    .setup-grid-item-start {
        width: 100%; /* Full width for start item */
    }
    .setup-text, .setup-drop {
        width: 100%; /* Full width for text inputs */
    }
    .setup-button {
        width: 100%; /* Full width for buttons */
    }

    /* Align labels to the left on mobile */
    .setup-grid-item-left,
    .setup-grid-item-left-spouse,
    .setup-grid-item-left-agent,
    .setup-grid-item-left-pref,
    .setup-grid-item-left-start {
        justify-content: flex-start; /* Align text to the left */
    }
}

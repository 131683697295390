.morecard {
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.149);
    border: 1px solid hsla(0, 0%, 0%, 0.279);
    backdrop-filter: blur(15px);
    border-radius: 14px;
    background: hsl(0, 0%, 100%);
    color: rgb(0, 0, 0);
    transition: box-shadow 0.5s;
    height: 90px;
    flex: 1;
    min-width: 0;
}

.morecards-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.more-table-input-like {
    border: 2px solid #d1d5db;
    padding: 3px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
}

.more-table-header, .green-more-table-header, .grey-table-header {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 4px;
    min-width: 60px;
}

.more-table-header {
    background-color: #00548c;
}

.green-more-table-header {
    background-color: #319b43;
}

.grey-table-header {
    background-color: #474c55;
}
.top-table-second-header {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #319b43;
}
.more-data-container {
    overflow-x: auto;
    max-height: 700px; /* Or whatever height you prefer */
    overflow-y: auto;}
.detailsToggleBtn {
    background-color: #319b43; /* Bootstrap primary blue */
    color: white;
    border: none;
    padding: 0.375rem 0.75rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
.more-table-header, .more-table-input-like {
    position: sticky;
    z-index: 2;
}
.detailsToggleBtn:hover {
    background-color: #267c34; /* A darker shade of primary blue */
    color: #fff;
    text-decoration: none;
}
/* Add this to your MoreDataTable.css */
.more-header-container {
    display: flex; /* This will align child elements in a row */
    justify-content: space-between; /* This will space the child elements at the start and end of the container */
    align-items: center; /* This will vertically align the child elements in the center */
    margin-bottom: 5px; /* This will add space between the header and the table */
    gap: 15px;
}
@media (max-width: 650px) {
    .morecard {
        flex-basis: 100%;
    }
}

.morebonus-table tr:nth-child(even) td {
    background-color: #ffffff;
}

.morebonus-table tr:nth-child(odd) td {
    background-color: #f1f1f1;
}

.buttonStyle {
    background: none;
    border: none;
    cursor: pointer;
    color: grey;
    font-size: 24px;
}

.buttonStyle:hover {
    color: black;
}
.more-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.disabledButtonStyle {
    background: none;
    border: none;
    cursor: default;
    color: #ccc;
}
.more-table-first-col {
    left: 0;
    z-index: 3; /* Ensures it stays above other cells when scrolling horizontally */
    white-space: nowrap;
}
.more-table-thead {
    position: sticky;
    top: 0;
    z-index: 100; /* Ensure the header is above other content */
}


.more-data-container:hover::-webkit-scrollbar-thumb {
    background-color: #c0c0c0; /* Changes the thumb color on hover */
}


.flex-row-container {
    display: flex; /* This will enable Flexbox for the container, placing child elements in a row */
    align-items: center; /* This will vertically center the child elements within the container */
    margin-bottom: 10px;
}
.codes-dropdown-left {
    width: 100%;
    margin-right: 10px;
    margin-left: 5px;
    z-index: 1000;
}

.codes-dropdown-right {
    width: 100%;
    margin-left: 10px;
    margin-right: 15px;
    z-index: 1000;

}
.codes-dropdown {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    
}
.activity-header h4 {
    display: flex; /* Aligns the text and the icon horizontally */
    align-items: center; /* Centers them vertically */
    cursor: pointer; /* Changes the cursor to indicate the text/icon can be clicked */
}

.toggle-more-view {
    margin-left: 10px; /* Adds some space between the text and the icon */
    display: flex; /* This ensures that the icon aligns properly with the text */
    align-items: center; /* Centers the icon vertically with respect to the text */
    scale: 0.7;
}

.toggle-icon {
    transition: transform 0.3s ease; /* Smooth transition for rotating icon */
}

.toggle-icon.open {
    transform: rotate(90deg); /* Rotate icon when the section is open */
}
/* Animation on hover */
.toggle-more-view:hover .toggle-icon {
    transform: scale(1.2); /* Enlarge the icon */
    transform: rotate(45deg);
}
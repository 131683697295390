/* StatCard.css */
.stat-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    width: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.stat-card h3 {
    margin: 0;
    font-size: 12px;
    color: #333;
}

.stat-card p {
    margin: 10px 0 0;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}



h1 {
    text-align: center;
    margin-bottom: 20px;
}

.tutorial-section {
    margin-bottom: 40px;
}

.tutorial-section h2 {
    margin-bottom: 20px;
}

.slick-slider {
    position: relative;
}

.slick-list {
    overflow: hidden;
    margin: 0 -10px;
}

.slick-slide {
    padding: 0 10px;
    box-sizing: border-box;
}

.video-container {
    width: 100%;
}

.video-container iframe {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: black;
}

@media (max-width: 768px) {
    .video-container iframe {
        height: 100px; /* Adjust the height as needed */
    }
}
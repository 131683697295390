.maxcard {
    position: relative;
    padding: 1rem;
    display: flex; /* Changed to flex for better control of internal layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Distribute space between items */
    align-items: start; /* Align items to the start */
    box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.149);
    border: 1px solid hsla(0, 0%, 0%, 0.279);
    backdrop-filter: blur(15px);
    border-radius: 14px;
    background: hsl(0, 0%, 100%);
    color: rgb(0, 0, 0);
    transition: box-shadow 0.5s;
    height: 90px;
    flex: 1; /* Flex grow to take available space */
    min-width: 0; /* Prevent flex items from growing beyond their content */
}
.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.maxcard-title {
    margin-top: 3px;
    font-size: 16px;
    text-align: left;
}

.maxcards-row {
    display: flex;
    justify-content: space-around; /* Distribute cards evenly */
    align-items: center;
    flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
    gap: 15px;
}
table {
	width: 100%; /* Full width of the container */
	border-collapse: separate; /* Removes space between borders */
	margin-bottom: 20px;
	border-spacing: 4px;
}
th, td {
	text-align: center;
	border: 4px solid transparent;
}
th {
	color: #ffffff; /* White text for header */
	font-size: 14px;
	font-weight: normal;
}
.table-input-like {
    border: 2px solid #d1d5db;
    padding: 6px;
    border-radius: 0.375rem;
    text-align: center;
    background-color: #fff; /* or any color you wish */
font-size: 10px;}
.top-table-header {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #00548c;
	padding-bottom: 4px;
  min-width: 60px;
}
.green-top-table-header {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #319b43;
	padding-bottom: 4px;
  min-width: 60px;
}
.grey-table-header {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #474c55;
	padding-bottom: 4px;
  min-width: 60px;
}
.top-table-second-header {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
	background-color: #319b43;
}

.expanded-detail {
    overflow: hidden;
    transition: max-height 5s ease-out;
    max-height: 0; /* Initially, the detail is collapsed */
}

.expanded-detail.open {
    max-height: 20px; /* Adjust this value based on the content size */
    /* You might need to adjust this value to fit the content of your expanded rows */
}
.more-data-container {
    overflow-x: auto; /* Enables horizontal scrolling */
  }
  
/* Media query for smaller screens */
@media (max-width: 650px) {
    .maxcard {
        flex-basis: 100%; /* Full width for small screens */
    }
}
/* MonthlyTotalsTable specific styles */
.monthly-totals-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.monthly-totals-table th, .monthly-totals-table td {
    text-align: center;
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    padding: 0.5rem;
}

.monthly-totals-table th {
    background-color: #00548cc3; /* Dark blue background */
    color: #ffffff; /* White text for headers */
    font-weight: bold;
}

.monthly-totals-table td {
    background-color: #fff; /* White background for cells */
    color: #000; /* Black text for cell content */
}

/* Adding a hover effect to rows */
.monthly-totals-table tr:hover {
    background-color: #f2f2f2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .monthly-totals-table {
        border: 0;
    }

    .monthly-totals-table th, .monthly-totals-table td {
        padding: 10px;
        text-align: left; /* Align text to left for smaller screens */
    }

    .monthly-totals-table th {
        display: none; /* Hide headers on small screens */
    }

    .monthly-totals-table td {
        display: block; /* Stack cells vertically */
        text-align: right; /* Align text to right for readability */
        position: relative;
    }

    .monthly-totals-table td::before {
        content: attr(data-label); /* Use data-label attribute value as content */
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px;
        font-weight: bold;
        text-align: left; /* Align label text to left */
    }
}


.monthly-totals-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonStyle {
    background: none;
    border: none;
    cursor: pointer;
    color: grey;
    font-size: 24px; /* Adjust the font size as needed */
}

.buttonStyle:hover {
    color: black; /* Change color on hover */
}

.disabledButtonStyle {
    background: none;
    border: none;
    cursor: default;
    color: #ccc; /* Light grey to indicate the button is disabled */
}
/* Alternating row background colors */
.maxbonus-table tr:nth-child(even) td {
    background-color: #e1e1e1; /* Light gray for even rows */
}

.maxbonus-table tr:nth-child(odd) td {
    background-color: #ffffff; /* White for odd rows */
}

.first-column-header {
    width: 100px; /* or any specific width you prefer */
}


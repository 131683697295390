/* Modal */
.custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .custom-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .custom-modal-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .custom-modal-close {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .custom-modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .custom-modal-input, 
  .custom-modal-select, 
  .custom-modal-file-input {
    margin-bottom: 15px;
    padding: 8px;
    font-size: 16px;
  }
  
  .custom-modal-submit {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .custom-modal-submit:hover {
    background-color: #0056b3;
  }
  
  .add-resource-btn {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 15px;
  }
  
  .add-resource-btn:hover {
    background-color: #218838;
  }
  
  .admin-manage-resources {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .view-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .view-toggle button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .view-toggle button.pressed {
    background-color: #007bff;
    color: white;
  }
  
  .view-toggle button:last-child {
    margin-right: 0;
  }
  
  .resources-list table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .resources-list table th, 
  .resources-list table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  .search-bar {
    margin-bottom: 20px;
    text-align: center;
    margin-top: 20px;
  }
  
  .search-bar input {
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
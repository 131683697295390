.send-email-button {
    padding: 5px 10px;
    background-color: #048415;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    min-width: 13ch;
}
.send-email-button:disabled {
    background-color: #cccccc; 
    cursor: not-allowed;
    opacity: 0.5; 
}
.compliance-table-input-like {
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
}

.search-container {
    display: flex;
    width: 100%;
    margin-bottom: 20px; /* Adds some space below the search bar */
    margin-top: 20px;
    align-items: center;
}

.search-container > input {
    flex: 0 0 auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
}

.search-container > select {
    flex: 1; 
margin-right: 5px;
padding: 5px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 12px;
}


/* Placeholder.css */

.placeholder-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Increased gap for better visibility */
    padding: 20px;
    margin-bottom: 20px;
}

.placeholder-line {
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    height: 20px;
    width: 30%;
    margin: 0 auto;
    border-radius: 4px;
    animation: placeholderAnimation 1s infinite linear;
}

.placeholder-box {
    background: linear-gradient(90deg, #d0d0d0 25%, #f0f0f0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    height: 200px;
    width: 100%;
    border-radius: 4px;
    animation: placeholderAnimation 1s infinite linear;
}

@keyframes placeholderAnimation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

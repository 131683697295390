.production-leaderboard-container {
    padding: 20px;
}
/* Remove padding and grid display in dashboardView */
.dashboard-dashboardView {
    display: block; /* Removes grid layout */
    padding: 0; /* Removes padding */
    width: 100%;
}

/* Make Top Producers leaderboard take full width */
.dashboard-dashboardView .leaderboard-left {
    width: 100%;
    max-width: none;
    height: auto;
    overflow-y: visible; /* Ensure it doesn’t scroll unnecessarily */
}
.dashboard-view-row {
    display: flex;
    justify-content: space-between; /* Aligns tabs to the left and date select to the right */
    align-items: center;
    padding: 10px 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.dashboard-view-row .tabs-filter-container {
    display: flex;
    justify-content: flex-start; /* Align tabs to the left */
    align-items: center;
    flex: 1;
}

.dashboard-view-row .date-select-container {
    display: flex;
    justify-content: flex-end; /* Align date select to the right */
    align-items: center;
    flex: 1;
}

.filters-row-container {
    display: flex;
    justify-content: space-between; /* Left, center, right alignment */
    align-items: center;
    padding: 10px 20px;
    gap: 20px;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}
.date-select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.filter-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    gap: 10px;
    margin-bottom: 5px;
}

.tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
.leaderboard-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.leaderboard-layout {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Left column takes 1/3, right takes 2/3 */
    gap: 20px; /* Space between columns */
    padding: 20px;
}

.leaderboard-left {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    height: calc(400px * 2 + 20px); /* Total height: 2 rows of 400px each + gap */
    overflow-y: auto; /* Scroll for overflow content */
}
.leaderboard-right {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr); /* Two per row on large screens */
    grid-auto-rows: 400px; /* Fixed height per leaderboard */
}

.leaderboard-child.all-data {
    height: 100%; /* Matches the height of the right column */
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Ensures scrolling if the content exceeds the available height */
}
.leaderboard-child {
    border: 2px solid #ddd;
    background-color: transparent;
    height: 100%; /* Match grid row height */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
/* Medium screens: Adjust to single leaderboard per row */

.fullscreen-button {
    background-color: transparent;
    color: #cccccc;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: color 0.3s ease-in-out;
    float: right; /* Align to the right */
}

.fullscreen-button:hover {
    color: #666;
    background-color: transparent;
}

/* Full-screen mode - Opens at a fixed position on the page */
.leaderboard-child.full-screen {
    position: absolute;
    top: 230px;  /* Adjust this value to set a fixed point on the page */
    left: 50%;
    transform: translateX(-50%);  /* Center it horizontally */
    width: 97%;
    height: 93%;
    background: white;
    z-index: 1000;
    overflow: auto;
}


/* Ensure full-screen table fills the space */
.leaderboard-child.full-screen .all-leaderboard-table {
    width: 100%;
    font-size: 16px;
    
}
/* Table styling */
.all-leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    text-align: left;
    font-size: 14px; /* Base font size */
}
.all-leaderboard-table thead {
    position: sticky;
    top: 0;
    background-color: #f5f5f5; /* Ensures visibility when scrolling */
    z-index: 2;
}

.all-leaderboard-table th,
.all-leaderboard-table td {
    padding: 10px 8px;
}


.all-leaderboard-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #333;
    font-size: 16px;
    border: none;
}

.all-leaderboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.all-leaderboard-table tr:nth-child(odd) {
    background-color: #ffffff;
}

/* Profile picture alignment */
.all-leaderboard-table td:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Adjust font size for Agent/SA/GA and Role columns */
.all-leaderboard-table td:nth-child(3),
.all-leaderboard-table td:nth-child(4) {
    font-size: 14px;
}
.all-leaderboard-table td:nth-child(2),
.all-leaderboard-table td:nth-child(3) {
    display: flex;
    align-items: center; /* Vertically align content */
    justify-content: flex-start; /* Align content closer to the left */
    gap: 4px; /* Control the spacing between the profile picture and the agent text */
    padding: 8px; /* Maintain consistent padding for all cells */
}.all-leaderboard-table td:nth-child(3) {
    font-size: 14px; /* Ensure readability */
    line-height: .8; /* Adjust line height for tighter spacing */
}

.experience-filter-container {
    display: flex;
    justify-content: flex-start; /* Left-align */
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    width: 33%;
}
.experience-filter-container span {
    cursor: pointer;
    padding: 2px 10px;
    transition: color 0.3s ease;
}

.experience-filter-container .separator {
    color: #555; /* Darker grey for | separator */
    padding: 0 1px;
}

.experience-filter-container .selected {
    color: #333; /* Darker grey for selected option */
}

.experience-filter-container .unselected {
    color: #c9c9c9; /* Lighter grey for unselected options */
}

.experience-filter-container span:hover {
    color: #666; /* Slightly darker grey on hover */
}
.toggle-container {
    display: flex;
    justify-content: flex-end; /* Right-align */
    font-size: 14px;
    font-weight: bold;
    width: 33%;
    user-select: none;
}
.toggle-container span {
    cursor: pointer;
    padding: 5px 5px;
    transition: color 0.2s ease-in-out;
}

.toggle-container .separator {
    color: #333; /* Keep separator a consistent darker grey */
    font-weight: bold;
}

.toggle-container .selected {
    color: #333;
    font-weight: bold;
}

.toggle-container .unselected {
    color: #c9c9c9;
}

.toggle-container span:hover {
    color: #666; /* Hover effect for better interaction */
}
.tabs-filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    flex-grow: 1; /* Allows tabs to remain centered */
    text-align: center;
}

.tabs-filter-container span {
    cursor: pointer;
    padding: 2px 10px;
    transition: color 0.3s ease;
}

.tabs-filter-container .separator {
    color: #555; /* Darker grey for | separator */
    padding: 0 1px;
}

.tabs-filter-container .selected {
    color: #333; /* Darker grey for selected option */
}

.tabs-filter-container .unselected {
    color: #c9c9c9; /* Lighter grey for unselected options */
}

.tabs-filter-container span:hover {
    color: #666; /* Slightly darker grey on hover */
}

.arrow-change-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #c9c9c9;
    padding: 5px;
    transform: scale(1.2);
    transition:  0.5s ease;
}

.arrow-change-button:hover {
    background-color: transparent;
    transition:  0.5s ease;
    color: #333;
}
/* Responsive adjustments */
@media (max-width: 1023px) {
    .all-leaderboard-table {
        font-size: 12px;
    }

    .all-leaderboard-table th {
        font-size: 14px;
    }

    .all-leaderboard-table td {
        padding: 8px 6px;
    }



}

@media (max-width: 767px) {
    .all-leaderboard-table {
        font-size: 12px;
    }

    .all-leaderboard-table th {
        font-size: 13px;
    }

    .all-leaderboard-table td {
        padding: 6px 4px;
    }
}

@media (max-width: 1550px) {
    .leaderboard-layout {
        grid-template-columns: 3fr 2fr; /* Left: 60%, Right: 40% */
    }
    .leaderboard-right {
        grid-template-columns: 1fr; /* One leaderboard per row */
    }
    .leaderboard-left {
        height: calc(400px * 4 + 60px); /* Adjust height for 3 rows */
    }
    .leaderboard-child.full-screen {
        top: 27%;
    }
}

@media (max-width: 1080px) {
    .filters-row-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5px;
    }

    .experience-filter-container,
    .toggle-container {
        width: 100%;
        justify-content: center;
    }
}
@media (max-width: 1080px) {
    .leaderboard-layout {
        grid-template-columns: 1fr; /* Single column for all leaderboards on small screens */
    }

    .leaderboard-right {
        grid-template-columns: 1fr;
    }
    .fullscreen-button {
        display: none; 
    }
}
